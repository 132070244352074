.c-detail-order {
    margin-left: 30px;
}
.c-block-5-p__heading-8 {
    margin-bottom: 15px;
    padding-bottom: 3px;
    color: #fff;
    font-size: 2.2rem;
    text-align: left;
    font-weight: 600;
}
.c-table-2__img {
    display: inline-block;
    max-width: 75px;
    overflow: hidden;
    border-radius: 5px;
    background: #fff;
}
@media only screen and (max-width: 1100px) {
    .c-detail-order {
        margin-left: 0px;
    }
    .c-table-2__img {
        max-width: 100px;
    }
}