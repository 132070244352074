[class*=g-col] {
    grid-column-end: span 7;
}
.c-block-5__pr1 {
    padding-right: 10px;
    max-width: 100%;
}
.c-block-5__col-2 {
    flex-wrap: wrap;
    align-items: center;
    grid-column-end: span 5;
}
.c-block-5__box-2 {
    max-width: 100%;
    margin: 0 auto;
}
.c-card-3__btn {
    display: block;
    padding: 12px 20px;
    transition: all .2s ease;
    cursor: pointer;
    border-color: #e2e3e4;
}
.staticDM{
    position: fixed;
    top: 80px;
    max-width: 30% !important;
}
.c-card-3 {
    border-radius: 5px;
    border: 1px solid #c9cbce;
    background: #e2e3e4;
}
.c-card-3__img {
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    background: #fff;
}
.c-slider-2__item {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
.c-card-3__box {
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    min-height: 56px;
}
.c-list-7 {
    display: -ms-flexbox;
    display: flex;
}
.c-card-3__list {
    padding-left: 20px;
}
.c-list-7__item {
    margin-right: 13px;
}
.c-list-5__color {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
}
.c-list-5__color:hover .c-list-5__color-boder {
    opacity: .4;
}
.c-list-5__color-boder {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    opacity: 0;
    transition: all .2s ease;
}
.c-list-7__item.slick-active .c-list-5__color-boder {
    opacity: 1!important;
}
img {
    max-width: 100%;
    width: 100%;
    height: auto;
    border: none;
}
.o-text-2 {
    line-height: 1.2;
    color: #3e6fff;
    font-weight: 600;
    font-size: 3.1rem;
}
.o-text-3 {
    line-height: 1.3;
    font-size: 2.6rem;
    color: #1b2546;
    font-weight: 600;
}
.c-block-5__p, .c-block-5__p-2, .c-block-5__p-3 {
    margin-bottom: 20px;
    color: #37383c;
}
.c-block-5__price {
    margin-bottom: 20px;
}
.o-text-4 {
    line-height: 1.2;
    font-size: 3.1rem;
    color: #3e6fff;
    font-weight: 600;
}
.o-text-5 {
    font-size: 1.7rem;
    color: #37383c;
}
.o-heading-5 {
    font-size: 4.1rem;
    font-weight: 600;
    line-height: 1.1;
    color: #1b2546;
    margin: 0px;
}
.c-block-5__btn-2 {
    margin-bottom: 20px;
    min-width: 50%;
    display: flex;
    flex-wrap: wrap;
}
.o-btn-3--v3 {
    border: none !important;
    background: #f18c17 !important;
    color: #0b0f1c !important;
    margin-right: 10px;
    margin-bottom: 10px;
    justify-content: left !important;
}
.o-btn-4--v3 {
    border: none !important;
    background: #3e6fff !important;
    color: #fff !important;
    margin-right: 10px;
    margin-bottom: 10px;
    justify-content: left !important;
    font-size: 1.3rem !important;
}
.o-btn-7--v2 {
    border-color: #3e6fff !important;
    color: #3e6fff !important;
    margin-right: 10px;
    margin-bottom: 10px;
    justify-content: left !important;
    font-size: 1.3rem !important;
}
.o-btn-3--v3:hover, .o-btn-4--v3:hover, .o-btn-7--v2:hover {
    border: none !important;
    background: #1b2546 !important;
    color: #fff !important;
}
@media only screen and (max-width: 1400px) {
    .staticDM{
        max-width: 95% !important;
        margin-right: 10px;
    }
}
@media only screen and (max-width: 750px) {
    [class*=g-col] {
        grid-column-end: span 12;
    }
    .c-block-5__col-2 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        grid-column-end: span 12;
    }
    .c-block-5__heading {
        margin-top: 25px;
    }
    .staticDM{
        position: inherit;
        margin-left: 10px;
    }
    .c-block-5__btn-2 {
        margin-bottom: 0px;
    }
}
@media only screen and (max-width: 600px) {
    .c-block-5__pr1 {
        padding-right: 0px;
    }
}
