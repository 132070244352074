.g-container-sc {
    width: 70%;
    margin: 0 auto;
  }   
  @media only screen and (max-width: 1400px) {
    .g-container-sc {
      width: 95%;
    }
  }
  @media only screen and (max-width: 1020px) {
    .g-container-sc {
      width: calc(100% - 40px);
      margin-right: 20px;
      margin-left: 20px;
    }
  }
  @media only screen and (max-width: 842px) {
    .g-container-sc {
      width: calc(100% - 40px);
      margin-right: 20px;
      margin-left: 20px;
    }
  }
  @media only screen and (max-width: 774px) {
    .g-container-sc {
      width: calc(100% - 0px);
      margin-right: 20px;
      margin-left: 20px;
    }
  }
  @media only screen and (max-width: 609px) {
    .g-container-sc {
      width: calc(100% - 20px);
      margin-right: 10px !important;
      margin-left: 10px !important;
    }
  }