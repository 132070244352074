.c-table-5__container {
    max-width: 600px;
    margin: 30px auto;
    background-color: #1e2a52;;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.c-table-5__container_Progress {
    height: 80px;
    text-align: center;
}
.pay {
    margin-top: 10px;
}
.detail-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
}
.text-label {
    font-weight: bold;
    color: #ffffff;
    font-size: 1.8rem;
}
.text-value {
    color: #ffffff;
    font-size: 1.8rem;
}
