.c-table-2-p__heading-null {
  color: #fff;
  font-weight: 600;
  text-align: center;
  color: #f18c17;
  font-size: 2.5rem;
}
.c-table-2-p__content-null {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 2rem;
}
.c-table-2-p__col-sol {
    min-width: 200px;
    flex: 1;
    padding: 0px 8px;
}
@media only screen and (max-width:1100px) {
    .c-table-2-p__col-sol {
      display: flex;
      border-bottom: 1px solid #28376b;
      margin-bottom: 10px;
      padding-bottom: 5px;
    }
  }
@media only screen and (max-width: 650px) {
    .c-table-2-p__col-sol {
      display: flex;
      border-bottom: 1px solid #28376b;
      margin-bottom: 10px;
      padding-bottom: 5px;
    }
  }
