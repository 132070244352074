body {
  background-color: #182140;
}
.g-container-l,
.g-container-l--grid {
  width: 100%;
  margin: 0 auto;
}
.c-block-4-IS {
  min-height: calc(100vh - 279px);
  padding-top: 40px;
  padding-bottom: 40px;
  margin: -20px 0px -30px 0px;
}
.c-form-1__btn {
  display: flex;
  justify-content: center;
}
.c-block-4-l__box-3 {
  max-width: 650px;
  margin: 0 auto;
}
.c-form-1-l,
.c-form-3-l {
  margin: 0 auto;
  padding: 25px 15px;
  border-radius: 5px;
  border: 1px solid #0e1327;
  background: #1e2a52;
}
.c-form-1-l__heading {
  margin-bottom: 20px;
  padding-bottom: 2px;
  border-bottom: 1px solid #f18c17;
  color: #f18c17;
  font-weight: 600;
  text-align: center;
  font-size: 1.8rem;
}
.c-form-1-l__field {
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  margin-bottom: 30px;
}
.o-form-label-l {
  display: block;
  margin-bottom: 6px;
  color: #a9b9ec;
  font-weight: 600;
}
.o-form-field-l,
.o-form-field-l--text-center,
.o-form-field-l--textarea,
.o-form-field-l--v2,
.selectize-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 90%;
  height: 40px;
  padding: 0 15px;
  border: 1px solid #0c1225;
  border-radius: 5px;
  outline: 0;
  background: #283768;
  font-size: 1.6rem;
  font-weight: 400;
  color: #758fe5;
  transition: all 0.3s ease;
}
a {
  outline: none;
  text-decoration: none;
  color: #5d86ff;
  transition: all 0.2s ease;
}
.o-form-field-l {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 93%;
  height: 40px;
  padding: 0 15px;
  border: 1px solid #0c1225;
  border-radius: 5px;
  outline: 0;
  background: #283768;
  font-size: 1.6rem;
  font-weight: 400;
  color: #758fe5;
  transition: all 0.3s ease;
}
.c-form-1__field {
  margin-bottom: 20px;
}
.o-form-field-l:focus {
  border: 1px solid #2f58e2;
}
.c-block-4__box-3 {
  max-width: 450px;
  margin: 0 auto;
}
.c-form-1__heading {
  margin-bottom: 20px;
  padding-bottom: 2px;
  border-bottom: 1px solid #f18c17;
  color: #f18c17;
  font-weight: 600;
  text-align: center;
  font-size: 1.8rem;
}
.c-notify--error:last-child,
.c-notify--ok:last-child,
.c-notify--primary:last-child,
.c-notify--secondary:last-child,
.c-notify:last-child {
  margin-bottom: 0;
}

.c-notify--error {
  background: #f9b7b7;
  color: #7b0b0b;
}
.c-form-1 {
  /* width: 89%; */
  padding: 30px 25px !important;
  border-radius: 5px;
  border: 1px solid #0e1327;
  background: #1e2a52;
}
.c-modal-1__box-2 {
  padding-top: 10px;
  text-align: center;
}
.o-text-6 {
  font-weight: 600;
  color: #fff;
}
.o-link-2 {
  color: #fba537;
}
.login_a_recovery {
  outline: none;
  text-decoration: none;
  color: #5d86ff;
  transition: all 0.2s ease;
}
.c-form-1__text-3 {
  margin-bottom: 10px;
}
.error_login_container {
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  vertical-align: baseline;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
}
.icon_error_login {
  margin: 13px;
}
*::-webkit-scrollbar {
  /* ? it's depend about if we want to hide the scroll bar in Y */
  /* width: 0; */
}
.error_login_message {
  align-self: center;
}
.c-block-4-l {
  padding-top: 40px;
  padding-bottom: 40px;
}
.c-block-4-l {
  background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg)
      100% 0 no-repeat,
    url(https://www.supre.com.co/images/bg-block-4-iz.9291ce49c3.svg) 0 100%
      no-repeat;
}
@media only screen and (max-width: 1400px) {
  .c-block-4-l {
    background: none;
  }
}
@media only screen and (min-width: 1200px) {
  .g-container-l,
  .g-container-r--grid {
    max-width: 1134px;
  }
}

@media only screen and (min-width: 992px) {
  .g-container-l,
  .g-container-l--grid {
    max-width: 970px;
  }
}

@media only screen and (min-width: 769px) {
  .g-container-l,
  .g-container-l--grid {
    max-width: 750px;
  }
}

@media only screen and (max-width: 768px) {
  .g-container-l,
  .g-container-l--grid {
    width: calc(100% - 40px) !important;
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 1300px) {
  .c-block-4-l {
    background: none;
  }
}

@media only screen and (min-width: 1200px) {
  .g-container-l,
  .g-container-r--grid {
    max-width: 1134px;
  }
}

@media only screen and (min-width: 992px) {
  .g-container-l,
  .g-container-l--grid {
    max-width: 970px;
  }
}

@media only screen and (min-width: 769px) {
  .g-container-l,
  .g-container-l--grid {
    max-width: 750px;
  }
}

@media only screen and (max-width: 768px) {
  .g-container-l,
  .g-container-l--grid {
    width: calc(100% - 40px) !important;
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 481px) {
  .c-form-1,
  .c-form-3 {
    padding: 30px 25px;
  }
}
@media only screen and (min-width: 481px) {
  .c-form-1-l,
  .c-form-3-l {
    padding: 30px 25px;
  }
}
