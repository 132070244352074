.c-card-4 {
    display: grid;
    grid-column-gap: 0;
    grid-template-columns: repeat(12, 1fr);
    border: 1px solid #0e1327;
    background: #1e2a52;
    border-radius: 5px;
}
.c-card-4__col-1 {
    grid-column-start: 1;
    grid-column-end: 7;
    padding: 25px 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
}
.c-card-4 .slider .slider-horizontal {
    width: 100%;
}
.slider-horizontal {
    margin-top: 30px;
}
.c-card-4__labels-meses {
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: -20px;
}
.slider-horizontal-input {
    width: 100%;
}
.c-card-4__icon-info {
    color: #f18c17;
    margin-left: 10px;
}
.icon-info2:before {
    content: "\ed64";
}
[class*=" icon-"]:after, [class*=" icon-"]:before, [class^=icon-]:after, [class^=icon-]:before {
    font-family: "icomoon"!important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.o-form-field--text-center:focus, .o-form-field--textarea:focus, .o-form-field--v2:focus, .o-form-field:focus, .selectize-input:focus {
    border-color: #3e6fff;
    background: #283768;
    color: #fff;
}
.o-form-field--text-center::placeholder {
    font-size: 1.3rem;
    letter-spacing: 0em;
}
.o-form-field--text-center {
    width: 100%;
    padding: 0px !important;
    appearance: none;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border: 1px solid #0c1225;
    border-radius: 5px;
    outline: 0;
    background: #283768;
    font-size: 1.3rem !important;
    font-weight: 400;
    color: #758fe5;
    transition: all .3s ease;
    text-align: center;
}
.tooltip-info {
    text-decoration:none;
    position:relative;
}
 .tooltip-info span {
    display:none;
}
 .tooltip-info span img {
    float:left;
}
 .tooltip-info:hover span {
    display:block;
    position:absolute;
    overflow:hidden;
}
.o-tooltip-top {
    position: absolute;
    width: 160px;
    border-radius: 5px;
    box-shadow: 0 2px 15px 0 rgba(0,0,0,.4);
    background: #f18c17;
    color: #131a31;
    font-size: 1.3rem;
    line-height: 1;
    text-align: center;
    font-weight: 600;
    transform: translateX(-85px);
    transition: all .2s ease;
    left: 50%;
    padding: 5px;
}
.c-card-4__col-2 {
    grid-column-end: span 6;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 25px 40px;
    background: #111831;
    text-align: center;
}
.c-card-4__heading-1 {
    margin-bottom: 10px;
    font-size: 2rem;
    color: #fff;
    font-weight: 600;
    line-height: 1.2;
}
.c-card-4__price {
    color: #f18c17;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.2;
    margin: 5px;
}
.c-card-4__and {
    margin-bottom: 5px;
    color: #fff;
    font-size: 2.1rem;
    font-weight: 600;
}
.c-card-4__btn {
    margin-top: 10px;
    margin-bottom: 10px;
}
.c-card-4__exclamation {
    color: #ff6a6a;
    margin: 0px;
    padding-top: 5px;
    padding-bottom: 10px;
    font-size: 1.4rem;
}
.value-cuota {
    text-align: center;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 30px;
    margin: 0px 0px 8px 0px;
    -webkit-text-fill-color: #f18c17;
    -webkit-text-stroke: 1px black
  }
.o-btn-3 {
    border: none;
    background: #f18c17;
    color: #0b0f1c;
    font-weight: 600;
    font-size: 1.5rem;
}
.c-card-4__text-1 {
    font-size: 1.4rem;
}
.c-card-4__box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
}
@media only screen and (max-width: 650px) {
    .c-card-4__col-1 {
        grid-column-start: 1;
        grid-column-end: 13;
    }
    .c-card-4__col-2 {
        grid-column-start: 1;
        grid-column-end: 13;
    }
    .c-card-4__col-1 {
        padding: 30px 25px;
    }
    .c-card-4__col-2 {
        padding: 30px 25px;
    }
    .c-card-4__cupon-ok {
        font-size: 1.5rem;
        margin-top: 4px;
    }
}