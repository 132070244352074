.c-block-5--v2 {
  background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg)
      100% 0 no-repeat,
    url(https://www.supre.com.co/images/bg-block-4-iz.9291ce49c3.svg) 0 100%
      no-repeat;
}
@media only screen and (max-width: 1400px) {
  .c-block-5--v2 {
    background: none;
  }
}
@media only screen and (max-width: 600px) {
  .jscroll-inner {
    display: block;
    grid-template-columns: none;
    grid-column-gap: 0px;
    transition: all 0.2s ease;
  }
}
