.c-info__box {
    padding: 25px;
}
.c-info__box-2-close {
    display: flex;
    justify-content: space-between;
}
.c-info__icon {
    cursor: pointer;
    color: #f18c17;
}
.c-info__name {
    font-size: 1.8rem;
    color: #fff;
    font-weight: 600;
}
.c-info__brand {
    font-size: 1.3rem;
    color: #3e6fff;
    font-weight: 600;
}
.c-info__price {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
}