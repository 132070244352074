/*Contenedor global*/
[class*=g-col] {
    -ms-grid-column-span: 6;
    grid-column-end: span 6;
}
/*Titulo principal*/
.o-heading-6 {
    text-align: center;
    font-size: 2.8rem;
    color: #1b2546;
}
/*Contenedor imagenes*/
.c-slider-3 {
    position: relative;
}
.c-block-5__mb-3 {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-around;
}
.c-slider-3__item {
    padding: 0 5px;
}
/*Contenedor de imagenes e imagenes*/
.c-slider-3__img {
    display: block;
    overflow: hidden;
    border-radius: 5px;
    border: 2px solid #0e1327;
}
.c-slider-3__img:hover {
    border: 2px solid #3e6fff;
}
.c-slider-3__img:hover img {
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}
.c-slider-3__img img {
    display: block;
    width: 100%;
    transition: all .2s ease;
}
/*Table*/
.c-table-1 {
    margin-bottom: 25px;
}
/*Titulo de la tabla*/
.c-table-1__header {
    padding-bottom: 12px;
    color: #f18c17;
    font-weight: 600;
    font-size: 2.1rem;
    border-bottom: 1px solid #f18c17;
    text-align: center;
}
/*Caja de la tabla*/
.c-table-1__box {
    border-radius: 0 0 5px 5px;
    border: 1px solid #d2d3d5;
    background: #e2e3e4;
}
.c-table-1__box {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}
.c-table-1__item {
    padding: 15px;
    flex: 1;
    min-width: 200px;
}
.c-table-1__item>* {
    width: 100%;
}
.c-table-1__title--v2 {
    color: #1b2546;
    font-weight: 600;
    font-size: 1.5rem;
}
.c-table-1__item {
    text-align: center;
}
.c-table-1__value--v2 {
    color: #5c5d61;
}
.c-table-1__item:nth-child(4n+2) {
    background: #d6d8d9;
}
.c-table-1__item:nth-child(4n+4) {
    background: #cfd1d2;
}
@media only screen and (max-width: 769px) {
    .c-table-1__item:nth-child(4n+2), .c-table-1__item:nth-child(4n+4) {
        background: #d6d8d9;
    }
    .c-table-1__item:nth-child(4) {
        background: #e2e3e4;
    }
    .c-table-1__item:nth-child(4n+3) {
        background: #cfd1d2;
    }
}
@media only screen and (max-width: 481px) {
    .c-block-5__mb-3 {
        display: grid;
        align-content: center;
        justify-content: center;
    }
    .c-slider-3__item {
        padding: 5px 5px;
    }
    .c-table-1__header {
        text-align: left;
    }
    .c-table-1__box {
        display: block;
    }
    .c-table-1__item:nth-child(2n) {
        background: #d6d8d9;
    }
    .c-table-1__item:nth-child(2n+1) {
        background: #e2e3e4;
    }
    .c-table-1__item {
        text-align: left;
    }
}