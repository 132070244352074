.c-nav-6--v2 {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    margin-right: 5px;
    margin-top: 0px !important;
    color: #3e6fff;
}
.c-list4__compare-v3 {
    padding-right: 11px;
    color: #3e6fff;
    cursor: pointer;
    transition: all .2s ease;
    font-weight: 600;
    font-size: 1.4rem;
}
.c-list4__compare-fn {
    padding-right: 11px;
    padding-top: 2px;
    color: #f18c17;
    cursor: pointer;
    transition: all .2s ease;
    font-weight: 600;
    font-size: 1.4rem;
}
.icon-arrow {
    margin-Top: 3px;
    padding-right: 11px;
    font-size: 1.4rem;
}