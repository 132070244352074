.c-block-5-ep,
.c-block-5-ep--v2 {
  padding-top: 15px;
  padding-bottom: 45px;
  background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg)
      100% 0 no-repeat,
    url(https://www.supre.com.co/images/bg-block-4-iz.9291ce49c3.svg) 0 100%
      no-repeat;
}
.c-form-1-ep__btn {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.g-container-ep {
  width: 70%;
  margin: 0 auto;
  max-width: 70%;
}
.c-nav-6-ep,
.c-nav-6-ep--v2 {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.g-container-ep--full-grid,
.g-container-ep--grid,
.g-row-ep {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 30px;
}
.c-nav-6-ep li a,
.c-nav-6-ep--v2 li a {
  font-weight: 600;
  font-size: 1.4rem;
}
a {
  outline: none;
  text-decoration: none;
  color: #5d86ff;
  transition: all 0.2s ease;
}
.icon-ep-home2:before {
  content: "\e901";
}
.c-nav-6-ep li:before,
.c-nav-6-ep--v2 li:before {
  margin-right: 10px;
  font-size: 1.1rem;
  color: #95b0ff;
}
.icon-ep-arrow-right3:before {
  content: "\edbd";
}
.c-nav-6-ep li a.active,
.c-nav-6-ep--v2 li a.active {
  color: #f18c17;
}
.c-block-4-ep__box-4 {
  max-width: 800px;
  margin: 0 auto;
}
.c-form-1-ep,
.c-form-3-ep {
  margin: 0 auto;
  padding: 25px 15px;
  border-radius: 5px;
  border: 1px solid #0e1327;
  background: #1e2a52;
}
.c-nav-6-ep li,
.c-nav-6-ep--v2 li {
  margin-left: 10px;
}
.c-form-1-ep__row {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 30px;
}
.c-form-1-ep__field {
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  margin-bottom: 30px;
}
.o-form-label-ep {
  display: block;
  margin-bottom: 6px;
  color: #a9b9ec;
  font-weight: 600;
}
.o-form-field-ep,
.o-form-field-ep--text-center,
.o-form-field-ep--textarea,
.o-form-field-ep--v2,
.selectize-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 40px;
  /* padding: 0 15px; */
  border: 1px solid #0c1225;
  border-radius: 5px;
  outline: 0;
  background: #283768;
  font-size: 1.6rem;
  font-weight: 400;
  color: #758fe5;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 1400px) {
  .g-container-ep {
    max-width: 95%;
    width: 95%;
  }
  .c-block-5-ep--v2 {
    background: none;
  }
}
@media only screen and (min-width: 481px) {
  .c-form-1-ep,
  .c-form-3-ep {
    padding: 30px 25px;
  }
  .c-form-1-ep__row {
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
