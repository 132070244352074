.accordeonContainer{
  border-radius: 15px;
  margin-bottom: 8px; 
  border: 1px solid #213B87;
  background-color: #1e2a52
}

.titleContainer{
  border-radius: 11px;
  background-color: #213B87;
}

.titleContainer__title{
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.paragraphs {
  color:white;
}