.o-form-field--textareaR{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 97%;
    height: 40px;
    padding: 10px 15px;
    border: 1px solid #0c1225;
    border-radius: 5px;
    outline: 0;
    background: #283768;
    font-size: 1.6rem;
    font-weight: 400;
    color: #758fe5;
    transition: all .3s ease;
    height: 150px;
}
.c-form-rq {
    width: 100%;
    padding: 30px 25px !important;
    border-radius: 5px;
    border: 1px solid #0e1327;
    background: #1e2a52;
}
.c-form-rq__row {
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-column-gap: 20px;
}
.c-form-1__field {
    grid-column-end: span 6;
}
.o-form-field--textareaR:focus{
    border: 1px solid #2f58e2;
}
#select-requerimiento{
    background: #283768!important;
    width: 102%;
    height: 40px;
    padding: 0 15px;
    border: 1px solid #0c1225;
    border-radius: 5px;
    outline: 0;
    background: #283768;
    font-size: 1.6rem;
    font-weight: 400;
    color: #758fe5;
    transition: all 0.3s ease;
}
.opciones{
    background: #304381;
    font-size: 1em;
    width: 90% !important;
    border: none;
}
.c-notify--ok_Requeriments{
    background: #8efdab;
    color: #161e38;
    display: flex;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 1.5rem;
    font-weight: 500;
}
.icon_check{
    padding-top: 5px;
    margin-right: 10px;
}
.o-form-field-rq, .o-form-field-rq--textarea {
    width: 100% !important;
    height: 39px;
    padding: 0px 0px 0px 5px;
    border: 1px solid #0c1225;
    border-radius: 5px;
    outline: 0;
    background: #283768;
    font-size: 1.6rem;
    font-weight: 400;
    color: #758fe5;
    transition: all .3s ease;
    color: #fff;
}
.o-form-field-rq:focus, .o-form-field-rq--textarea:focus {
    border: 1px solid #2f58e2;
}
.o-form-field-rq--textarea {
    height: 150px;
}
.o-form-text-help {
    margin-top: 0px;
    margin-bottom: 15px;
}
@media only screen and (max-width: 1300px){
     .c-block-4__col{
        padding: 0px 20px;
        justify-content: center;
    }
    .c-block-4__col--moto{
        padding: 0px 20px;
    }
    .c-form-rq {
        max-width: 700px;
    }
}
@media only screen and (max-width: 992px) {
    .c-block-4__box{
        padding: 0px;
        margin: auto;
        margin-bottom: 20px;
    }
    .c-block-4__col--moto{
        grid-column-end: span 6;
    }
    .c-block-4__col{
        grid-column-end: span 6;
    }
    .c-block-4__p {
        max-width: 660px;
        text-align: center;
        line-height: 1.6;
    }
}
@media only screen and (max-width: 700px) {
    .c-form-rq {
      margin-bottom: 10rem;
    }  
}
@media only screen and (max-width: 550px){
    .o-form-field--textareaR{
        width: 94%;
    }
    .c-form-rq {
        padding: 30px 25px;
    }
    .c-form-1__field {
        grid-column-end: span 12;
    }
}