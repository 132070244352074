.c-block-4__col--loc {
    -ms-grid-column-span: 6;
    grid-column-end: span 6;
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    padding: 30px 0px 0px 25px;
}
.c-block-4__col--loc:last-child {
    padding-left: 20px;
}
.c-block-4__box-2, .c-block-4__list {
    width: 100%
}
.c-list-3, .c-list-3--v2 {
    column-count: 2;
    column-gap: 30px;
}
.c-list-3__item {
    break-inside: avoid;
    display: -ms-flexbox;
    display: flex;
    margin: 0px;
    margin-bottom: 30px;
}
.c-list-3__items  {
   margin: 0px;
}
.c-list-3__items li {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    font-size: 1.6rem;
}
.c-list-3__items li:before {
    padding-top: 7px;
    margin-right: 7px;
    font-size: .9rem;
}
.IconRight{
    margin-right: 10px;
    font-size: 12px;
    padding-top: 5px;
}
.c-list-3__icon {
    width: 20px;
    margin-right: 15px;
    color: #f18c17;
    font-size: 2rem;
}
.c-list-3__box {
    -ms-flex: 1;
    flex: 1;
}
.c-list-3__heading {
    font-weight: 600;
    font-size: 2rem;
    font-family: "myriad-pro",sans-serif;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0px !important;
    margin-top: 0px;
}
.c-list-3__p {
    margin: 0px;
}
.c-block__separador {
    height: 1px;
    margin-bottom: 15px;
    background: #263771;
}
.c-list-3__p-2 {
    font-size: 1.3rem;
    color: #3e6fff;
    margin: 0px;
}
.c-list-3__p-3 {
    font-size: 1.4rem;
    margin: 0px;
}
@media only screen and (max-width: 1100px){
    .c-block-4__col--v2{
        width: 92%;
        margin: auto;
        margin-bottom: 30px;
    }
    .c-block-4__col--v2:last-child{
        padding-left: 0px ;
        padding-top: 20px ;
    }
}
@media only screen and (max-width: 850px){
    .c-block-4__col--loc {
        padding: 30px 25px 0px 25px;
    }
}
@media only screen and (max-width: 500px){
    .c-block-4__col--v2{
        margin-left: 3%;
        width: 85%;
    }    
}
@media only screen and (max-width: 499px){
    .c-list-3, .c-list-3--v2 {
        column-count: 1;
    }
}

