.c-modal-1__tags-filter, .c-modal-1__tags-filter--v2 {
    display: inline-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 91.5%;
    padding: 12px 15px;
    background: #1c3273;
}
.c-list-10 {
    padding: 20px;
    transition: all .2s ease;
    display: grid;
}
.jscroll-inner {
    grid-template-columns: repeat(12,1fr);
    display: grid;
    grid-column-gap: 10px;
}
.c-list-10__item {
    grid-column-end: span 6;
    position: relative;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #0e1327;
    background: #1e2a52;
    cursor: pointer;
    transition: all .2s ease;
}
.c-list-10__hover {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(14,19,39,.85);
    transition: all .2s ease;
    opacity: 0;
}
.c-list-10__hover:hover{
    opacity: 1;
}
.c-list-10__img {
    position: relative;
    border-radius: 5px 5px 0 0;
    background: #25325d;
}
.c-list-10__img img {
    width: 100%;
}
.c-list-10__box {
    padding: 10px;
    text-align: center;
}
.c-list-10__name{
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
    line-height: 1.3;
}
.c-list-10__brand{
    color: #3e6fff;
    font-size: 1.4rem;
}