.g-container-dp {
  width: 70%;
  margin: 0 auto;
  max-width: 70%;
}
.c-block-5--v3 {
  padding-top: 0px;
  padding-bottom: 30px;
  background-color: #eaebec !important;
  background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg)
      100% 0 no-repeat,
    url(https://www.supre.com.co/images/bg-block-4-iz.9291ce49c3.svg) 0 100%
      no-repeat;
}
/*DETAIL PRODUCT*/
.g-row-v1 {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
}

@media only screen and (max-width: 1400px) {
  .g-container-dp {
    max-width: 95%;
    width: 95%;
  }
  .c-block-5--v3 {
    background: none;
  }
}
@media only screen and (max-width: 481px) {
  /*DETAIL PRODUCT*/
  .g-container-dp {
    max-width: 90%;
    width: 90%;
  }
  .g-row-v1 {
    grid-template-columns: repeat(12, 1fr);
  }
}
