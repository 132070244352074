.card-related{
    background-color: #242947;
	border-radius: 1.6rem;
	cursor: pointer;
    transition: transform 0.1s; /* Agregar transición suave */
    border: none;
    margin: 1rem;
}
.card-related:hover{
    transform: scale(1.02);/* Zoom en hover (1.2 es un valor de ejemplo) */
    transform-origin: center; /* Centrar el zoom en el centro de la imagen */
    border: 1px solid;
}
.secondContainer-related{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
}
.text-related{
    width: 100%;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    font-weight: 800;
    color: #fff;
    align-items: center;
}
.image-related {
    border-radius: 16px;
    object-fit: fill;
    /*width: 280px;*/
    width: 100%;
    height: 170px;
    background-size: cover;
    background-position: center; 
}
.news-cards-root-related{
    padding-left: 0 !important;
    padding-top: 0 !important;
    overflow: hidden;
    position: relative;
}
.button-item-related{
    color: #F18C17;
    text-transform: none;
    font-size: 1.8rem;
}
.button-container-related{
    display: flex;
    justify-content: space-between;
}
.button-direction-related{
    margin-top: 40px;
}
.container-related{
    display: flex;
}
.container-img {
    width: 70%;
}
@media only screen and (min-width: 600px) {
    .card-container{
        padding-left: 15rem;
    }
}
@media screen and (min-width: 900px) and (max-width: 1050px) {
    .card-container{
        padding-left: 1rem;
    }
}
@media only screen and (max-width: 608px) {
    .image-related {
        height: 120px;
    }
    .button-direction-related{
        margin-top: 10px;
    }
}