.c-block-4-rp {
  padding: 40px 0px;
  min-height: calc(100vh - 279px);
  background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg)
      100% 0 no-repeat,
    url(https://www.supre.com.co/images/bg-block-4-iz.9291ce49c3.svg) 0 100%
      no-repeat;
}
.g-container-rp,
.g-container-rp--grid {
  width: 100%;
  margin: 0 auto;
}
.c-block-4-rp__box-3 {
  max-width: 450px;
  margin: 0 auto;
}
.c-form-1-rp,
.c-form-3-rp {
  /* width: 100%; */
  padding: 25px 15px;
  border-radius: 5px;
  border: 1px solid #0e1327;
  background: #1e2a52;
}
.c-form-1-rp__heading {
  margin-bottom: 20px;
  padding-bottom: 2px;
  border-bottom: 1px solid #f18c17;
  color: #f18c17;
  font-weight: 600;
  text-align: center;
  font-size: 1.8rem;
}
.c-form-1-rp__text-intro {
  margin-bottom: 10px;
  line-height: 1.4;
  text-align: center;
}
.c-form-1-rp__field {
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  margin-bottom: 20px;
}
.o-form-rp-label {
  display: block;
  margin-bottom: 6px;
  color: #a9b9ec;
  font-weight: 600;
  text-align: center;
}
.o-form-rp-field,
.o-form-rp-field--text-center,
.o-form-rp-field--textarea,
.o-form-rp-field--v2,
.selectize-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 98%;
  height: 40px;
  padding: 0 0 0 5px;
  border: 1px solid #0c1225;
  border-radius: 5px;
  outline: 0;
  background: #283768;
  font-size: 1.6rem;
  font-weight: 400;
  color: #758fe5;
  transition: all 0.3s ease;
}
.c-modal-1-rp__box-2 {
  text-align: center;
}
.o-text-6-rp {
  font-weight: 600;
  color: #fff;
}
.o-link-2 {
  color: #fba537;
}
.c-form-1-rp__btn {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1400px) {
  .c-block-4-rp {
    background: none;
  }
}
@media only screen and (min-width: 1200px) {
  .g-container-rp,
  .g-container-rp--grid {
    max-width: 1134px;
  }
}

@media only screen and (min-width: 992px) {
  .g-container-rp,
  .g-container-rp--grid {
    max-width: 970px;
  }
}
@media only screen and (min-width: 769px) {
  .g-container-rp,
  .g-container-rp--grid {
    max-width: 750px;
  }
}

@media only screen and (max-width: 768px) {
  .g-container-rp,
  .g-container-rp--grid {
    width: calc(100% - 40px) !important;
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 481px) {
  .c-form-1-rp,
  .c-form-3-rp {
    padding: 30px 25px;
  }
}
