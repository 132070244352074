.c-modal-card--right {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    transform: translateX(0px);
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .5);
    background: #213b87;
    transition: all .2s ease;
    left: auto;
    right: 0;
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    overflow: scroll;
}
.c-modal-card--right.open {
    transform: translateX(0);
}
.c-modal-card__header-bar {
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    padding-left: 20px;
    border-bottom: 1px solid #17295e;
    background: #2d54c7;
    padding-left: 0;
    padding-right: 20px;
}
.c-modal-card__close {
    appearance: none;
    border: none;
    outline: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    height: 45px;
    padding: 0 20px;
    background: transparent;
    font-weight: 600;
    font-size: 1.6rem;
    color: #fff;
    transition: all .2s ease;
    cursor: pointer;
}
.c-modal-card__close:hover {
    background: #294cb3;
}
.c-modal-card__close i {
    height: 21px;
    margin-right: 8px;
    font-size: 1.8rem;
}
.c-info__icon-modal {
    margin-right: 10px;
}
.c-modal-card__header-title {
    color: #fff;
    font-weight: 600;
    font-size: 1.7rem;
}
.tab {
    font-size: 1.3rem !important;
    color: #fff !important;
}
.tab-active {
    color: #f18c17 !important;
}
.c-modal-card__footer-btns {
    position: relative;
    z-index: 10;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .4);
    border-top: 1px solid #3e6fff;
    background: #162a64;
}
.c-modal-card__gran-total {
    padding: 10px 20px;
    text-align: center;
}
.c-modal-card__gran-total-text-1 {
    font-weight: 700;
    font-size: 2.3rem;
    color: #fff;
}
.c-modal-card__gran-total-text-1 .total {
    color: #f18c17;
}
.c-modal-card__footer-box, .c-modal-card__footer-box-2 {
    padding: 0 15px 14px;
}
.c-modal-card__footer-box {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
}
.c-modal-card__footer-btn-col {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
}
.o-btn-card, .o-btn-card--v2 {
    appearance: none;
    border: none;
    outline: 0;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: 0 20px;
    border-radius: 5px;
    font-size: 1.3rem;
    line-height: 1.1;
    font-weight: 600;
    transition: all .2s ease;
    cursor: pointer;
}
.o-btn-card {
    background: #3e6fff;
    color: #fff;
}
.o-btn-card--v2 {
    background: #f18c17;
    color: #0f1425;
}
.o-btn-card--v2:hover, .o-btn-card:hover {
    background: #fff;
    color: #0f1425;
}