.c-modal-1{
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    -ms-transform: translateX(-400px);
    transform: translateX(-400px);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 350px;
    height: 100%;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .5);
    background: #213b87;
    transition: all .2s ease;
}
.ps {
    overflow-y: scroll;

}
.c-modal-1__content::-webkit-scrollbar , .c-list-6::-webkit-scrollbar{
    width: 3px;
 
}
.c-modal-1__content::-webkit-scrollbar-thumb, .c-list-6::-webkit-scrollbar-thumb{
    background: #3e6fff;
    border-radius: 5px;
}

.c-modal-1__content {
    position: relative;
    -ms-flex: 1;
    flex: 1;
    height: 87% !important;
    padding-bottom: 50px;
}
.c-modal-1.open {
    -ms-transform: translateX(0);
    transform: translateX(0);
}
.c-modal-1__header-bar, .c-modal-1__header-bar--v2 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    height: 46px;
    padding-left: 20px;
    border-bottom: 1px solid #17295e;
    background: #2d54c7;
}
.c-modal-1__header-bar--v2 {
    padding-left: 0;
    padding-right: 20px;
}
.c-modal-1__header-title {
    color: #fff;
    font-weight: 600;
    font-size: 1.7rem;
}
.c-modal-1__close {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    height: 45px;
    padding: 0 20px;
    background: transparent;
    font-weight: 600;
    font-size: 1.6rem;
    color: #fff;
    transition: all .2s ease;
    cursor: pointer;
}
.c-modal-1__close:hover {
    background: #294cb3;
}
.icon-cross2 {
    height: 21px;
    margin-right: 8px;
    font-size: 1.8rem;
}
.c-modal-1__tags-filter {
    display: inline-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 94.4%;
    padding: 12px 0px;
    padding-left: 20px;
    background: #1c3273;
}
.o-is-hidden{
    display: none !important;
}
.c-modal-1__section {
    padding: 10px 20px;
    margin-bottom: 10px;
}
.c-modal-1__section:first-child {
    padding-top: 20px;
}
.c-modal-1__section:last-child {
    padding-bottom: 20px;
    margin-bottom: 0;
}
.c-modal-1__section-2 {
    padding: 10px;
}
.c-modal-1__section-2:first-child {
    padding-top: 20px;
    width: 100%;
}
.c-modal-1__heading {
    margin-bottom: 15px;
    color: #fff;
    font-size: 1.7rem;
    font-weight: 600;
}
.c-modal-1__item {
    margin-bottom: 15px;
    
}
.c-list-6 {
    position: relative;
    overflow-y: auto;
    max-height: 145px;
}
.c-list-6__item{
    margin-bottom: 10px;
}
.c-list-6 .ps__rail-y {
    width: 6px!important;
    background-color: rgba(0, 0, 0, .3)!important;
    border-radius: 10px;
    opacity: 1!important;
}
.c-list-6 .ps {
    right: 0!important;
    width: 6px!important;
    background: #3e6fff!important;
}
.c-modal-1__item:last-child {
    margin-bottom: 0;
}
.c-modal-1__row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.c-modal-1__row .o-form-label {
    width: 100%;
    color: #fff;
    font-size: 1.5rem;
}
.c-modal-1__field {
    position: relative;
    -ms-flex: 1;
    flex: 1;
}
.o-form-field--v2 {
    width: 98% !important;
    height: 39px;
    padding: 0 0 0 5px;
    border: 1px solid #0c1225;
    border-radius: 5px;
    outline: 0;
    background: #283768;
    font-size: 1.6rem;
    font-weight: 400;
    color: #758fe5;
    transition: all .3s ease;
}
.o-form-field--v2 {
    color: #fff;
}
.o-form-field--v2, .o-form-field--v2:hover {
    background: #2749ac;
}
.o-form-field--v2:focus {
    border-color: #0c1225;
    background: #2d53c4;
}
.c-modal-1__btn {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
}
.c-modal-1__btn button {
    position: relative;
}
.o-btn-6 {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 5px;
    border: 2px solid #3e6fff !important;
    background: #3e6fff;
    color: #fff;
    font-size: 1.4rem;
    transition: all .2s ease;
    cursor: pointer;
}
.o-btn-6:hover {
    background: #f18c17;
    border: 2px solid #f18c17 !important;
    color: #0b0f1c;
}
.c-modal-1__col {
    -ms-flex: 1;
    flex: 1;
}
.c-modal-1__col:first-child {
    margin-right: 15px;
}