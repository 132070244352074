.c-form-3__row-isc {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0px;
}
.c-form-isc {
  width: 100%;
  padding: 25px 0px;
  border-radius: 5px;
  border: 1px solid #0e1327;
  background: #1e2a52;
}
input:-webkit-autofill,
input:-webkit-autofill:hover {
  box-shadow: 0 0 0px 10000px #283768 inset;
  -webkit-box-shadow: 0 0 0px 10000px #283768 inset;
  -webkit-text-fill-color: #758fe5;
  -webkit-text-size-adjust: none;
  transition: background-color 5000s ease-in-out 0s;
  font-family: "myriad-pro", sans-serif;
}
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
}
.o-form-label-isc {
  display: block;
  color: #a9b9ec;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.4;
}
.c-form-3__field-isc {
  /* width:100%; */
  margin: 0px 0px 20px 0px;
  padding: 0px 15px;
}
.c-form-3__cont-field {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0px;
}
.o-form-field-isc {
  -webkit-appearance: none;
  appearance: none;
  width: 98%;
  height: 40px;
  border: 1px solid #0c1225;
  border-radius: 5px;
  background: #283768;
  font-size: 1.6rem;
  font-weight: 400;
  color: #758fe5;
  padding: 0 0 0 5px;
  outline: 0;
}
.o-form-field-isc:focus {
  border: 1px solid #3e6fff;
}
#select-solicitud {
  background: #283768 !important;
  width: 100%;
  height: 42px;
  /* padding: 0 55px;  */
  border: 1px solid #0c1225;
  border-radius: 5px;
  outline: 0;
  background: #283768;
  font-size: 1.6rem;
  font-weight: 400;
  color: #758fe5;
  transition: all 0.3s ease;
}
#select-solicitud:focus {
  border: 1px solid #3e6fff;
}
.opciones {
  background: #304381;
  font-size: 1em;
  width: 100% !important;
  border: none;
}
.o-form-check {
  padding-left: 3px;
}
.c-form-3__btns-isc {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px 0px 20px;
}
.o-btn-2--isc {
  border: 1px solid #3e6fff;
  color: #fff;
  height: 40px;
  padding: 0 24px;
  border-radius: 20px;
  background: transparent;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
}
.o-btn-2--isc:hover {
  background-color: #32468a;
}
@media only screen and (max-width: 900px) {
  .c-form-3__row-isc {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width: 700px) {
  .c-form-isc {
    margin-bottom: 10rem;
  }  
}
@media only screen and (max-width: 500px) {
  .c-form-3__cont-field {
    grid-template-columns: repeat(1, 1fr);
  }
}
