.c-block-5-p__heading-6 {
    margin-bottom: 10px;
    padding-bottom: 3px;
    color: #f18c17;
    font-size: 2.5rem;
    text-align: center;
    font-weight: 600;
}
.c-table-2-p,
.c-table-2-p--v2 {
    display: block;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    background: #1e2a52;
    color: #a9b9ec;
    font-size: 1.6rem;
}
.c-table-2-p__row {
  display: flex;
}
.c-table-2-p__col {
  flex: 1;
  padding: 0px 8px;
  /* min-width: 110px; */
}
.c-table-2-p__col--btn {
    flex: 1;
    padding: 0px 8px;
    min-width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.c-table-2-p__heading {
  color: #fff;
  font-weight: 600;
  text-align: center;
}
.c-table-2-p__content {
  text-align: center;
  display: flex;
  justify-content: center;
}
.o-btn-or {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: 0;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  border-radius: 20px;
  border: 2px solid #f18c17;
  background: transparent;
  font-size: 1.5rem;
  color: #f18c17;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
  margin-left: 4px;
}
.o-btn-or:hover {
  background: #f18c17;
  color: #0b0f1c;
}
@media only screen and (max-width:1100px) {
    .c-block-5-p__tables {
        display: grid;
        grid-template-columns: repeat(12,1fr);
        grid-column-gap: 30px;
    }
    .c-table-2-p--v2 {
        grid-column-end: span 6;
    }
    .c-table-2-p__col {
      display: flex;
      border-bottom: 1px solid #28376b;
      margin-bottom: 10px;
      padding-bottom: 5px;
    }
    .c-table-2-p__row {
      display: flex;
      flex-direction: column;
    }
    .c-table-2-p__heading {
      width: 120px;
      margin-right: 15px;
      text-align: left;
    }
  }
@media only screen and (max-width: 650px) {
    .c-table-2-p--v2 {
        grid-column-end: span 12;
    }
    .c-table-2-p__col {
      display: flex;
      border-bottom: 1px solid #28376b;
      margin-bottom: 10px;
      padding-bottom: 5px;
    }
    .c-table-2-p,
    .c-table-2-p--v2 {
      margin-bottom: 20px;
    }
    .c-table-2-p__row {
      display: flex;
      flex-direction: column;
    }
    .c-table-2-p__heading {
      width: 120px;
      margin-right: 15px;
      text-align: left;
    }
  }