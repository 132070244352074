
.card{
    background-color: #25335B;
	border-radius: 1.6rem;
	cursor: pointer;
    border: 0.5px solid  #F18C17;
    transition: transform 0.1s; /* Agregar transición suave */
}

.card:hover{
    transform: scale(1.02);/* Zoom en hover (1.2 es un valor de ejemplo) */
    transform-origin: center; /* Centrar el zoom en el centro de la imagen */
}

.secondContainer{
    width: 100%;
    margin-top: 10px;
    min-height: 15rem;
}
.text{
    width: 100%;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.7rem;
    font-weight: 900;
    color: #fff;
    align-items: center;
}
.text-detail{
    width: 100%;
    text-align: center;
    font-weight: 300;
    color: #fff;
}

.divider{
    margin-left: 10px;
    width: 90%;
    background-color: #fff;
}

.footer{
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
}

.text-footer{
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
    color: #dadada;;
    font-size: 1.4rem;
}

.button{
    color: #F18C17;
    text-transform: none;
    font-size: 1.4rem;
}


.image {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    object-fit: fill;
}

.news-card-root{
    padding-left: 0 !important;
    padding-top: 0 !important;
    overflow: hidden;
    position: relative;
}

.card-container{
    width: 100%;
   
}
@media only screen and (min-width: 600px) {
    .card-container{
        padding-left: 15rem;
    }
  }

  @media only screen and (max-width: 750px) {
    .card-container{
        padding-left: 1rem;
         width: 90%;
    }

    .secondContainer{
        width: 100%;
        margin-top: 10px;
    }
  }