.c-block-5__section-1-sc {
    margin-bottom: 20px;
}
.content-row-sc {
    display: grid;
    grid-template-columns: repeat(14,1fr);
    grid-column-gap: 20px;
}
.g-col-offset-xl-3-sc {
    grid-column-start: 1 !important;
    grid-column-end: 5 !important;
}
.g-col-xl-6-sc-1 {
    grid-column-start: 6 !important;
    grid-column-end: 14 !important;
}
.g-col-xl-4-sc {
    grid-column-start: 1 !important;
    grid-column-end: 5 !important;
}
.g-col-xl-8-sc {
    grid-column-start: 5 !important;
    grid-column-end: 15 !important;
}
.c-block-5__heading-4--v2-sc {
    text-align: center;
    color: #f18c17;
    font-size: 2.6rem;
    font-weight: 600;
    line-height: 1.3;
}
.c-block-5__heading-4--v3-sc {
    text-align: center;
    color: #c0cdf5;
    font-size: 2.7rem;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 20px;
}
.c-block-5__heading-5-sc {
    text-align: center;
    color: #c0cdf5;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.3;
    margin-top: 14px;
    margin-bottom: 20px;
}
.c-block-5__box-2 {
    max-width: 100%;
}
.c-list-4__item-2--vacio-sc {
    margin-bottom: 30px;
    border-radius: 5px;
    border: 1px solid #0e1327;
    background: #1e2a52;
    transition: all .2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 280px;
    width: 100%;
}
.o-btn-4 {
    font-size: 1.3rem !important;
}
@media only screen and (max-width: 950px){
    .g-col-offset-xl-3-sc {
        grid-column-start: 2 !important;
        grid-column-end: 15 !important;
    } 
    .g-col-xl-6-sc {
        grid-column-start: 2 !important;
        grid-column-end: 14 !important;
    }
    .g-col-xl-6-sc-1 {
        grid-column-start: 2 !important;
        grid-column-end: 14 !important;
    }
    .g-col-xl-4-sc {
        grid-column-start: 5 !important;
        grid-column-end: 11 !important;
    }
    .g-col-xl-8-sc {
        grid-column-start: 2 !important;
        grid-column-end: 14 !important;
    }
}
@media only screen and (max-width: 650px) {
    .content-row-sc {
        grid-template-columns: repeat(14,1fr);
        grid-column-gap: 10px;
    }
    .g-col-offset-xl-3-sc {
        grid-column-start: 1 !important;
        grid-column-end: 14!important;
    }
    .g-col-xl-4-sc {
        grid-column-start: 2 !important;
        grid-column-end: 14 !important;
    }

    .g-col-xl-6-sc {
        grid-column-start: 2 !important;
        grid-column-end: 14 !important;
    }
    .g-col-xl-6-sc-1 {
        grid-column-start: 2 !important;
        grid-column-end: 14 !important;
    }
    .g-col-xl-8-sc {
        grid-column-start: 2 !important;
        grid-column-end: 14 !important;
    }
}