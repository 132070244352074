@import url("https://use.typekit.net/uga8stg.css");
.g-container2 {
  width: 70%;
  margin: 0 auto;
  max-width: 70%;
}
.c-footer {
  background: #1b2546;
  position:fixed; 
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.c-footer__logo img {
  display: inline-block;
  width: 100px;
}
.c-footer__container {
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.c-footer__col,
.c-footer__container {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  -ms-flex-align: center;
  align-items: center;
}
.c-footer__container {
  text-align: center;
}
.c-footer__container_copy {
  text-align: center;
  display: block;
  margin: 0 auto; 
  font-size: 1.2rem;
  padding-bottom: 1rem;
}
.c-nav-2 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
}
.c-nav-2 li {
  margin: 0px 10px;
}
.c-nav-2 li:last-child {
  margin-right: 0;
}
.c-nav-2 li a {
  font-size: 1.5rem;
  color: #5d86ff;
  font-weight: 600;
}
.c-nav-2 li a:hover {
  color: #fff;
}
.c-footer__nav {
  margin-left: 30px;
}
.c-nav-4 {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.c-nav-4 li {
  margin-left: 5px;
  margin-top: 3px;
}
.c-nav-4 li:first-child {
  margin-left: 0;
}
.c-nav-4 li a {
  color: #5d86ff;
  font-size: 1.6rem;
}
.c-nav-4 li a:hover {
  color: #fff;
}
.c-nav-4 li a span {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .g-container2 {
    max-width: 95%;
    width: 95%;
  }
}
@media only screen and (max-width: 1000px) {
  .c-footer__nav {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 700px) {
  .c-footer__col,
  .c-footer__container {
    display: block;
    grid-auto-flow: column;
    -ms-flex-align: center;
    align-items: center;
  }
  .c-footer__logo img {
    margin-top: 15px;
  }
  .c-footer__logo {
    display: block;
  }
  .c-footer__nav {
    margin: 10px;
    padding: 0px;
  }
  .c-nav-4 {
    margin: 0px 0px 10px 0px;
    padding: 0px;
  }
}