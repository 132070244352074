/*  Para el spinner de carga  */
@keyframes b {
    0% {
    transform: rotate(0deg);
}
to {
    transform: rotate(359deg);
}
}
.menu-mobile-overlay {
    background: rgba(27, 37, 70, .95);
    overflow: hidden;
    z-index: 101;
}
.loader-popup, .menu-mobile-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.loader__box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.loader__box [class*=icon] {
    width: 30px;
    height: 30px;
    font-size: 30px;
    line-height: 1;
    color: #fff;
}
.animate-spin {
    animation: b .6s infinite linear;
}
.icon-spinner9:before {
    content: "\eb2a"
}
/* fin */

.g-containerNBC{
    max-width: 70%;
    margin: auto;    
}
.c-header-catalogo{
    height: 62px !important;
    z-index: 101;
    margin-bottom: 15px;
}
/* .op{
    padding-left: 350px;
    width: calc(100% - 350px) !important;
} */
.static{
    border-top: 2px solid #3e6fff;
    position: fixed;
    margin-top: 72px;
    width: 100%;
}
.static2{
    display: none !important;
}
.static3{
    padding-top: 250px;
}
.c-nav-5 {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 60px;
    border-radius: 0 0 5px 5px;
    background: #213b87;
    transition: all .1s ease;
}
#header-catalogo-sticky-wrapper.is-sticky .c-nav-5 {
    height: 50px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
}
#header-catalogo-sticky-wrapper.is-sticky .c-nav-5:before {
    position: absolute;
    top: -2px;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    background: #3e6fff;
}
.c-nav-5__col {
    display: -ms-flexbox;
    display: flex;
}
.c-nav-5__col-center {
    color: #71a6ff;
    font-weight: 600;
}
.c-nav-5__col-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.c-nav-5__btn, .c-nav-5__btn--v2, .c-nav-5__btn--v3 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 20px;
    border-bottom-left-radius: 5px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    border-right: 1px solid #192f6d;
}
.c-nav-5__btn--v2.active, .c-nav-5__btn--v2:hover, .c-nav-5__btn--v3.active, .c-nav-5__btn--v3:hover, .c-nav-5__btn.active, .c-nav-5__btn:hover {
    background: #1d3477;
}
.c-nav-5__btn-icon {
    margin-right: 12px;
}
.c-nav-5__btn-text2 {
    margin-left: 15px;
    margin-top: -3px;
    font-weight: 400;
    font-size: 1.5rem;
}
.c-nav-5__btn-text2 i {
    margin-left: 6px;
    font-size: 1.1rem;
}
.c-nav-5__btn--v2 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px;
}
.c-nav-5__btn--v3 {
    padding: 0 15px;
    border-bottom-left-radius: 0;
    color: #9fb7ff;
}
.c-nav-5__btn--v3.active, .c-nav-5__btn--v3:hover {
    background: none;
    color: #fff;
}
.c-nav-5__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 20px;
}
.c-nav-5__list li {
    margin-right: 16px;
}
.c-nav-5__list li:last-child {
    margin-right: 0;
}
.c-nav-5__list li div {
    color: #3e6fff;
}
.c-nav-5__list li div:hover {
    color: #fff;
}
.c-nav-5__list li div.active {
    color: #f18c17;
}
.list-order {
    cursor: pointer;
}
.c-nav-5__menu-order1,  .c-nav-5__menu-order2{
    position: relative;
    z-index: 101;
    display: flex;
}
.c-nav-1__submenu--v2{
    display: none;
    position: absolute;
    top: calc(100% + 10px);
    overflow: hidden;
    width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
    background: #304381;
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
    transition: all 0.2s ease;
}
.c-nav-1__submenu--v2.open{
    display: initial;
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
    margin: 0;
}
.c-nav-1__submenu--v2 li, .c-nav-1__submenu--v3 li {
    border-bottom: 1px solid #1b2546;
}
.c-nav-1__submenu--v2 li:last-child, .c-nav-1__submenu--v3{
    border-bottom: none;
}
.c-nav-1__submenu--v2 li a, .c-nav-1__submenu--v3 li a{
    display: -ms-flexbox;
    display: flex;
    padding: 13px 15px;
    color: #fff;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.4;
}
.c-nav-1__submenu--v2 li a:hover, .c-nav-1__submenu--v3 li a:hover{
    background: #374d95;
    color: #dde5ff;
}
.c-nav-1__submenu--v2 li a.activo, .c-nav-1__submenu--v3 li a.activo{
    background: #3d5fcc;
    color: #fff;
}
.c-nav-1__submenu--v2, .c-nav-1__submenu--v3 {
    top: calc(100% - 5px);
    right: 20px;
    background: #3159ce !important;
}
.c-nav-1__submenu--v2 li, .c-nav-1__submenu--v3 li {
    border-bottom-color: rgba(24, 44, 103, .5);
}

.c-nav-1__submenu--v2 li a:hover, .c-nav-1__submenu--v3 li a:hover {
    background: #3e6fff;
}
.icon-arrow-down3{
    margin-left: 5px;
    padding-top: 6px;
}

/* Ordenar invisible (solo se activa cuando llega a 992px) */
.c-nav-5__box {
    margin-bottom: 15px;
    display: none;
    display: -ms-flexbox;
    justify-content: flex-end !important;
    display: none;
}
.c-nav-5__btn--v3{
    background-color: none;
    color: #ffffff;
    font-size: 1.4rem;
}

.c-nav-1__submenu--v3{
    display: none;
    position: absolute;
    top: calc(100% + 10px);
    overflow: hidden;
    width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
    background: #304381;
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
    transition: all 0.2s ease;
}
.c-nav-1__submenu--v3.open{
    display: initial;
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
    margin: 0;
}
.submenu_orden{
    cursor: pointer;
    background: transparent;
    padding: 13px 15px;
    color: #fff;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.4;
}
.submenu_orden:hover{
    background: #3e6fff;
}
@media only screen and (max-width:1328px) {
    .op{
        padding-left: 0px;
        width: 100% !important;
    }
}


@media only screen and (max-width: 1200px){
    .g-containerNBC{
        max-width: 970px;
        margin: auto;
    }
    .c-nav-5__box{
        display: none;
    }
    .icon-arrow-down3{
        padding-top: 5px !important;
    }
}

@media only screen and (max-width: 992px){
    .g-containerNBC{
        max-width: 750px;
        margin: auto;
    }
    .c-nav-5__box{
        display: flex;
    }
    .c-nav-5__menu-order1{
        display:none;
    }
    .icon-arrow-down3{
        padding-top: 0px !important;
    }
    .c-nav-5__col:first-child{
        order:2;
        padding-right: 20px;
    }
    .c-nav-5__col-center {
        position: initial !important;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding-left: 20px;
        transform: none;
    }
}

@media only screen and (max-width: 768px){
    .g-containerNBC{
        width: calc(100% - 40px);
        margin-right: 20px;
        margin-left: 20px;
    }    
}

@media only screen and (max-width: 480px){
    .c-nav-5__col-center {
        display: none;
    }
    .c-nav-5__col{
        width: 100%;
        justify-content: space-between !important;
        
    }
    .c-nav-5__btn{
        border-right: 0px;
    }
    .c-list-4__img {
        width: 100% !important;
    }
}