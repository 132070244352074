.c-modal-1 {
    width: 400px;
}
.c-modal-1__section-2:first-child {
    padding-top: 20px;
}
.o-is-hidden-2{
    display: none !important;
}
.BG:first-child{
    width: auto !important;
}
.o-form-label-bg {
    display: block;
    width: 100%;
    margin-bottom: 6px;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: left;
}
.c-chatbot {
    padding-left: 55px;
    min-width: 85%;
    max-width: 85%;
    margin: 0 auto;
}
.c-chatbot__item {
    margin-bottom: 20px;
    display: block;
}
.c-chatbot__container {
    display: flex;
    flex-wrap: wrap;
    opacity: 1;
    transform: translateX(-5px);
    transition: all .2s ease;
    padding-right: 8px;
}
.c-chatbot__question {
    position: relative;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0 2px 15px 0 rgb(0 0 0 / 20%);
    background: #fff;
    transition: all .2s ease;
}
.c-chatbot__question:before {
    position: absolute;
    left: -6px;
    bottom: 20px;
    content: "";
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 10px solid #fff;
}
.c-chatbot__photo {
    left: -53px;
    position: absolute;
    bottom: 0px;
    text-align: center;
}
.c-chatbot__photo img {
    max-width: 40px;
    height: auto;
    border: none;
}
.c-chatbot__time {
    font-size: 1.1rem;
}
.c-chatbot__text-2{
    font-size: 1.7rem;
    color: #131a31;
}
.c-chatbot__question p {
    font-size: 1.47rem;
    color: #1b2546;
    margin: 0;
}
.c-chatbot__text-1{
    margin-bottom: 10px;
    font-size: 1.15rem;
    font-weight: 600;
    color: #fff;
}
.c-chatbot__btns-box {
    transition: all .4s ease;
}
.o-btn-8 , .o-btn-8_S3, .o-btn-8_S4{
    -webkit-appearance: none;
    appearance: none;
    border: none;
    outline: 0;
    display: block;
    min-width: 270px;
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    background: rgba(62,111,255,.6);
    text-align: left;
    font-weight: 600;
    font-size: 1.2rem;
    color: #fff;
    transition: all .2s ease;
    cursor: pointer;
}
.o-btn-8:hover, .o-btn-8_S3:hover, .o-btn-8_S4:hover {
    background: #3e6fff;
}
.checked {
    background: #f29223;
    color: #131a31;
    display: block !important;
    cursor:default;
}
.checked:hover{
    background-color: #f29223 !important;
}
.c-chatbot__btns-box-2>* {
    margin-bottom: 7px;
}
.Mostrar{
    background-color: #f29223 !important;
    
    color: #131a31 !important;
}
.Mostrar:hover{
    background-color: #fff !important;
    border: 2px solid #fff;
}
.js_error{
    margin: 0px;
    padding-top: 5px;
    font-size: 1.4rem;
    color: #ff6a6a;
}
@media only screen and (max-width: 450px) {
    .c-modal-1 {
        max-width: 95%;
        /* width: 450px; */
    }
}
