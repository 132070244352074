.c-table-2--v2 {
    display: block;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    background: #1e2a52;
    color: #a9b9ec;
    font-size: 1.6rem;
}
.c-block-5-al__p-2 {
    margin: 10px 0px;
}
.o-form-field-al {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 97%;
    height: 40px;
    padding: 0 15px;
    border: 1px solid #0c1225;
    border-radius: 5px;
    outline: 0;
    background: #283768;
    font-size: 1.6rem;
    font-weight: 400;
    color: #758fe5;
    transition: all .3s ease;
}