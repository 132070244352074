.c-block-4 {
  min-height: calc(100vh - 269px);
  padding-top: 40px;
  padding-bottom: 40px;
  margin: -20px 0px -30px 0px;
  background: none !important;
}
.c-block-4__container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr);
    grid-template-columns: repeat(12,1fr);
    grid-column-gap: 30px;
}
.g-containerC{
    max-width: 70%;
    margin: auto;
}
@media only screen and (max-width:1400px){
    .g-containerC  {
        max-width: 95%;
        width: 95%;
    }  
    .c-block-4 {
      background: none;
    }
}
@media only screen and (max-width: 1100px) {
  .g-containerC {
    max-width: 993px !important;
    margin: auto;
  }
  .c-block-4__container {
    grid-template-columns: none;
  }
}

@media only screen and (max-width: 400px) {
  .c-block-4 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

}
