body {
  background: #182140;
}
.c-block-5 {
  padding-top: 45px;
  padding-bottom: 45px;
  background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg)
      100% 0 no-repeat,
    url(https://www.supre.com.co/images/bg-block-4-iz.9291ce49c3.svg) 0 100%
      no-repeat;
  min-height: 70vh;
}
.g-container-tyc {
  max-width: 70%;
  margin: auto;
}
.c-card {
  padding: 30px 30px;
  border-radius: 5px;
  border: 1px solid #0e1327;
  background: #1e2a52;
  z-index: 1;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
}
.o-heading-2 {
  margin-top: 40px;
  margin-bottom: 15px;
  font-size: 2.1rem;
  font-family: "myriad-pro", sans-serif;
  font-weight: 700;
  color: #fff;
}
.o-heading-2:first-child {
  margin-top: 0;
}
.c-list-1 {
  list-style: decimal;
  padding-left: 20px;
}


/* Estilo para los elementos de lista (li) en listas principales */
ol.c-lista-1 > li,
ol.c-list-2 > li {
    margin-left: 30px;
    list-style: decimal; /* Utiliza numeración decimal para las listas principales */
    margin-bottom: 15px;
}
.c-list-1 li {
  margin-bottom: 15px;
  list-style: decimal;
}
.c-list-2 {
  padding-left: 20px;
}
.c-lista-2 li {
  list-style: disc;
}
.c-list-2 li {
  list-style: disc;
}
.c-list-1__title {
  font-family: "myriad-pro", sans-serif;
  font-weight: 700;
  color: #fff;
}
@media only screen and (max-width: 1400px) {
  .g-container-tyc {
    max-width: 95%;
    width: 95%;
  }
  .c-block-5 {
    padding-top: 30px;
    padding-bottom: 140px;
    background: none;
  }
  .c-card {
    padding: 20px 20px;
  }
}
