@keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }
  
  /* Estilos base */
  .skeleton-card {
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #0e1327;
    background: #1e2a52;
    transition: all 0.2s ease;
    padding: 16px;
    width: 405px;
  }
  
  .skeleton-card:hover {
    border-color: #3e6fff;
  }
  
  /* Efecto shimmer */
  .skeleton-text,
  .skeleton-color,
  .skeleton-price,
  .skeleton-icon,
  .skeleton-card__image {
    background: linear-gradient(90deg, #25325d 25%, #3e56a5 50%, #25325d 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
    border-radius: 4px;
  }
  
  /* Iconos superiores */
  .skeleton-card__icons {
    display: flex;
    justify-content: space-between;
  }
  
  .skeleton-icon {
    width: 24px;
    height: 24px;
  }
  
  /* Imagen */
  .skeleton-card__image {
    width: 100%;
    height: 180px;
    border-radius: 5px;
  }
  
  /* Contenedor de información */
  .skeleton-card__details {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  /* Encabezado */
  .skeleton-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Título y marca */
  .skeleton-text {
    height: 20px;
  }
  
  .skeleton-text--large {
    width: 80%;
  }
  
  .skeleton-text--medium {
    width: 60%;
  }
  
  .skeleton-text--small {
    width: 100%;
    height: 16px;
  }
  
  /* Pie de tarjeta */
  .skeleton-card__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .skeleton-color {
    width: 50px;
    height: 16px;
  }
  
  .skeleton-price {
    width: 60px;
    height: 24px;
  }
  