.o-btn-5 {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 4px 10px;
    margin: 5px;
    border-radius: 5px;
    background: #f18c17;
    font-size: 1.4rem;
    color: #0b0f1c;
    font-weight: 600;
    text-align: left;
    transition: all .2s ease;
}
.o-btn-5:hover {
    background: #fff;
}
.o-btn-5 [class*=icon] {
    margin-left: 6px;
    height: 16px;
}
