.divider{
    background-color: #f3f3f3;
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
    margin-left: 0px;   
}
.container{
    display: flex;
}
.text-description{
    width: 100%;
    font-size: 2.5rem;
    font-weight: 900;
    color: #fff
}
.text-description-related{
    margin-top: 10px;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: 850;
    color: #F18C17
}
.paragraphs {
    font-size: 2rem;
}
.large-image{
    border-radius: 16px;
}
.container-news-related{
    border: 1px solid #0C61AF;
    background-image: #25335B;
    border-radius: 16px;
}
.back-button{
    margin-top: 20px;
    color: #F18C17;
    text-transform: none;
    font-size: 1.5rem;
}
.container-button {
    padding-top: 20px; 
    padding-bottom: 20px;
}
.container-card {
    padding: 20px;
}
@media only screen and (max-width: 608px) {
    .text-description{
        font-size: 1.7rem;
    }
}