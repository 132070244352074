.modal-info {
    padding: 30px;
    line-height: 3rem;
}
.modal-info-container {
    display: flex;
}
.modal-info-title {
    width: 130px;
    text-align: right;
    color: #fff;
    font-weight: 600;
    font-size: 1.5rem;
    margin-right: 15px;
}
.modal-info-client {
    color: #A9B9E6;
    font-weight: 600;
    font-size: 1.5rem;
}
.modal-info-client-select {
    color: #A9B9E6;
    font-weight: 600;
    font-size: 1.5rem;
}
.modal-info-btn-col {
    margin: 20px;
    padding: 0px 80px;
}
.modal-info-register {
    color: #A9B9E6;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2rem;  
    text-align: center; 
}