.g-containerCM {
  width: 1134px;
  margin: auto;
}
.c-block-CM {
  background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg)
      100% 0 no-repeat,
    url(https://www.supre.com.co/images/bg-block-4-iz.9291ce49c3.svg) 0 100%
      no-repeat;
}
.c-block-5__heading-3 {
  padding: 20px 15px 0px;
  margin-top: 0px;
}
.o-heading-7 {
  font-size: 1.9rem;
  color: #f18c17;
  font-weight: 600;
  text-align: center;
}
.c-list-4--comparador {
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  grid-column-gap: 30px;
}
.staticCM {
  width: 1134px;
  position: fixed;
  top: 70px;
  grid-column-gap: 0;
  box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
}
.c-list-4__item-2--vacio {
  grid-column-end: span 4;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 230px;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #0e1327;
  background: #1e2a52;
  transition: all 0.2s ease;
}
.static2CM {
  height: 150px;
  margin-bottom: 0px;
  min-height: 150px;
}
.static3CM {
  padding-top: 430px;
}
.staticinfo {
  display: flex !important;
  margin-bottom: 0 !important;
  border-radius: 0;
  max-height: 150px !important;
}
/* para las tablas */
.c-table-1 {
  margin-bottom: 25px;
}
.c-table-1__header-2 {
  padding-bottom: 12px;
  color: #f18c17;
  font-weight: 600;
  font-size: 2.1rem;
  border-bottom: 1px solid #3e6fff;
  text-align: center;
}
.c-table-1__box-2 {
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  grid-column-gap: o;
  border-radius: 0 0 5px 5px;
  border: 1px solid #0e1327;
  background: #1e2a52;
}
.c-table-1__item-2 {
  grid-column-end: span 4;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding: 15px;
  text-align: center;
}
.c-table-1__item-2 > * {
  width: 100%;
}
.c-table-1__title {
  color: #fff;
  font-weight: 600;
  font-size: 1.7rem;
}
.c-table-1__vacio-1 {
  width: 100%;
  max-width: 200px;
  height: 12px;
  margin: 0 auto 10px;
  background-color: hsla(0, 0%, 100%, 0.1);
}
.c-table-1__value {
  font-size: 1.5rem;
}
.c-table-1__vacio-2 {
  width: 100%;
  max-width: 150px;
  height: 9px;
  margin: 0 auto;
  background-color: rgba(169, 185, 236, 0.08);
}
.c-table-1__item-2:nth-child(6n),
.c-table-1__item-2:nth-child(6n + 2),
.c-table-1__item-2:nth-child(6n + 4) {
  background: #222f5d;
}
.c-table-1__item-2:nth-child(6n + 5) {
  background: #253465;
}
.icon-plus2 {
  margin-right: 10px;
}

/* div cuando estan las motos cargadas */
.c-list-4__item-2 {
  grid-column-end: span 4;
  display: block;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #0e1327;
  background: #1e2a52;
  transition: all 0.2s ease;
}
.c-list-4__item-2:hover {
  border: 1px solid #5d86ff;
}
.img5 {
  position: relative;
  border-radius: 5px 5px 0 0;
  background: #25325d;
}
.img5 img {
  height: 100%;
}
.c-list-4__box-5 {
  padding: 10px 20px 10px 20px;
  text-align: center;
  position: relative;
}
.c-list-4__data {
  margin-bottom: 15px;
}
.c-list-4__name {
  font-size: 1.9rem;
  color: #fff;
  font-weight: 600;
}
.c-list-4__brand {
  font-size: 1.3rem;
  color: #3e6fff;
  font-weight: 600;
}
.c-list-4__price-2 {
  color: #f18c17;
  font-weight: 600;
  font-size: 2.1rem;
}
.c-list-4__text-1 {
  color: #a9b9ec;
  font-size: 1.3rem;
}
.c-list-4__icons-grid--v2 {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0;
}
.c-list4__close {
  padding: 5px;
  color: #f18c17;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.2s ease;
}
.c-list4__close:hover {
  color: #ffffff;
}
.closeview {
  display: none;
  padding: 0 10px 0 0;
  top: 0;
  position: absolute;
}
.staticCM5 {
  flex: 1 !important;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.color {
  display: flex;
  justify-content: center;
}

/* Botones comprar y solicitar credito */
.c-list-9 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 10px;
  padding-top: 10px;
}
.c-list-9__item {
  grid-column-end: span 4;
  padding-bottom: 20px;
}
.c-list-9__btns--v2 {
  margin-bottom: 25px;
  text-align: center;
}
.c-list-9__btns--v2 > * {
  display: flex;
  min-width: 170px;
  margin: 0 auto 7px;
}
.c-list-9__btn-1,
.c-list-9__btn-2 {
  display: block !important;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 20px;
  background: #f18c17;
  font-size: 1.5rem;
  color: #0b0f1c;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
}
.c-list-4__btn-add,
.c-list-10__btn {
  background-color: #f18c17 !important;
  color: #0b0f1c !important;
}
.c-list-9__btn-2 {
  background-color: #3e6fff;
  color: white;
}
.c-list-9__btn-1:hover,
.c-list-9__btn-2:hover,
.c-list-10__btn:hover {
  background-color: #ffffff !important;
  color: #0b0f1c !important;
  border-color: #ffffff !important;
}
.icon-cart-add {
  margin-right: 10px;
}
/* media query */
@media only screen and (max-width: 1400px) {
  .c-block-CM {
    background: none;
  }
}
@media only screen and (max-width: 1200px) {
  .g-containerCM {
    width: 970px;
  }
  .c-list-4--comparador {
    width: 970px;
  }
}
@media only screen and (max-width: 992px) {
  .c-list-4__icons-grid--v2 {
    top: 0px;
  }
  .g-containerCM {
    width: 750px;
  }
  .c-list-4--comparador {
    width: 750px;
  }
  .img5 {
    height: initial;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
  }
  .img5 img {
    height: auto;
  }
}
@media only screen and (max-width: 768px) {
  .g-containerCM {
    width: calc(100% - 40px);
    margin-right: 20px;
    margin-left: 20px;
  }
  .c-list-4--comparador {
    width: 100%;
    grid-column-gap: 0px;
  }
  .staticCM {
    width: calc(100% - 40px);
  }
  .staticCM img {
    display: none;
  }
  .img5 {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  #aggmoto {
    display: none;
  }
  .icon-plus2 {
    margin-right: 0px;
  }
  .staticCM img {
    display: none;
  }
  .c-list-9__btns--v2 > * {
    width: calc(100% - 10px) !important;
    height: 36px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0 10px;
    font-size: 1.3rem;
    line-height: 1.2;
    min-width: 0px;
  }
  .c-list-9__btn-2 {
    font-size: 1.2rem !important;
  }
}
@media only screen and (max-width: 480px) {
  .c-modal-1 {
    width: 310px;
  }
  .c-modal-1__tags-filter--v2 {
    width: auto;
  }
  .c-list-10__btn {
    width: 100px;
  }
  .c-list-4__box-5 {
    padding: 15px 10px !important;
    text-align: center !important;
  }
  .c-list-4__name {
    font-size: 1.6rem !important;
  }
  .c-list-4__price-2 {
    font-size: 1.6rem !important;
  }
  .c-list-4__text-1 {
    font-size: 1.2rem !important;
  }
  .staticCM.img5 {
    width: 0px !important;
  }
  .c-list-9 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    padding-top: 10px;
  }
  .c-list-9__item {
    grid-column-end: span 2;
  }
  .staticinfo > .img5 {
    width: 0px !important;
  }
}
@media only screen and (max-width: 400px) {
  .icon-cart-add {
    display: none;
  }
}
