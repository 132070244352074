
.status-container{
display: flex;
justify-content: space-between;
}
.text-status{
    font-size: 16px;
    color: #fff
}
.type-status{
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    align-self: center;
    justify-content: center;
    height: 16px;
    color: #fff;
}

.detail-container {
    display: flex;
    justify-content: space-between;
}

.detail-text{
    margin-top: -14px;
    color: #fff;
}

.text-detail-value{
    margin-top: -6px;
    color:#fff
}

.text-detail-reference{
    margin-top: -10px;
    font-size: 12px;
}