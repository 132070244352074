
/* .c-nav-7-p li a.active {
    background: #3d5fcc;
    color: #fff;
} */
.c-nav-lis-p {
    cursor: pointer;
}
.g-row-p {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-column-gap: 20px;
}
.col-p-xl-3 {
    grid-column-end: span 4;
}
.col-p-xl-9 {
  grid-column-end: span 16;
}
.c-nav-7-p {
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
    background: #304381;
    margin-top: 10px;
}
.c-nav-7-p li {
    border-bottom: 1px solid #1b2546;
}
.c-nav-6-p--v2 li div.active {
    color: #f18c17;
}
.c-nav-7-p li div.active {
    background: #3d5fcc;
    color: #fff;
}
.c-nav-7-p li div {
    display: flex;
    padding: 13px 15px;
    color: #aabfff;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.4;
}
.c-nav-7-p li:last-child {
    border-bottom: none;
}
@media only screen and (max-width: 991px) {
    .col-p-xl-3 {
        grid-column: 1 / 10
    }
    .col-p-xl-9 {
        grid-column: 1 / 21;
    } 
    .c-nav-7-p {
        max-width: 350px;
        margin: 0 auto 25px;
    }
}
@media only screen and (max-width: 500px) {
    .col-p-xl-3 {
        grid-column: 1 / 13
    }
    .col-p-xl-9 {
        grid-column: 1 / 19;
    } 
}