
.carousel {
  padding-top: 37%;
  perspective: 10px;
}
.carousel__viewport {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: hidden;  
  height: 120%;
}
.carousel__slide {
  position: relative;
  width: 200%;
}
.carousel__slide.active  {
  flex: 1 0 100%;
}
.c-slider-1__img-movil {
  padding-top: 55px;
  max-width: 100%;
  height: auto;
  display: block;
  opacity: 0;
  object-fit: cover;
  /* transition: opacity 1s ease-in-out; */
}
.c-slider-1__img-movil.active {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}
.Iconleft,
.Iconright {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  color: #3e6fff;
  z-index: 10;
  background-color: #ffffff38;
  width: 3rem;
  height: 3rem;
  border-radius: 40%;
}
.Iconright {
  right: 15px;
}
.Iconleft {
  left: 15px;
}
.Iconleft:hover,
.Iconright:hover {
  color:#f18c17;
  cursor: pointer;
}
@media only screen and (max-width: 1020px) {
  .c-slider-1__img-movil {
    padding-top: 56px;
  }
}
@media only screen and (max-width: 520px) {
  .c-slider-1__img-movil {
    padding-top: 55px;
    height: 100%; /* Ajusta esto según lo necesites */
    object-fit: cover;
  }
  .carousel {
    padding-top: 80%;
    overflow: hidden;
  }
  .Iconleft,
  .Iconright {
    width: 2rem;
    height: 2rem;
    top: 40%;
  }
  .Iconright {
    right: 20px;
}
}
