.c-list-4__item {
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #0e1327;
    background: #1e2a52;
    transition: all .2s ease;
    grid-column-end: span 4;
}
.c-list-4__item:hover {
    border-color: #3e6fff;    
}
.c-list-4__img {
    position: relative;
    border-radius: 5px 5px 0 0;
    background: #25325d;
    aspect-ratio: 1/0.6;
}
.c-list-4__icons-grid {
    display: flex;
    justify-content: space-between;
    height: 0px;
    padding: 0;
    position: relative;
}
.c-list4__compare, .c-list4__wish {
    padding: 15px;
    color: #3e56a5;
    font-size: 1.8rem;
    cursor: pointer;
    transition: all .2s ease;
    z-index: 100;
}
.c-list4__compare2, .c-list4__wish2{
    padding-top: 5px;
    padding-left: 15px;
    color: #3e56a5;
    font-size: 1.8rem;
    cursor: pointer;
    transition: all .2s ease;
}
.c-list-4__item:hover .c-list4__compare.active, .c-list4__compare2:hover , .c-list4__wish2:hover, .c-list-4__item:hover .c-list4__compare:hover, .c-list-4__item:hover .c-list4__wish.active, .c-list-4__item:hover .c-list4__wish:hover {
    color: #f18c17;
}
.c-list-4__img img {
    display: block;
    width: 100%;
    transition: all .2s ease;
}

.c-list-4__box{
    padding:15px;
}
.c-list-4__heading {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.c-list-4__box-2 {
    -ms-flex: 1;
    flex: 1;
}
.c-list-4__name {
    font-size: 1.9rem;
    color: #fff;
    font-weight: 600;
}
.c-list-4__brand {
    font-size: 1.3rem;
    color: #3e6fff;
    font-weight: 600;
}
.c-list-4__p {
    display: none;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #a9b9ec;
    font-size: 1.5rem;
}
.o-tooltip-bottom-v2 {
    position: absolute;
    z-index: 100;
    width: 100px;    
    border-radius: 5px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .4);
    background: #f18c17;
    color: #131a31;
    font-size: 1.3rem;
    line-height: 1.2;
    text-align: center;
    font-weight: 600;
    opacity: 0;
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    transition: all .2s ease;
    left: 6%;
    -ms-transform: translateX(-50%) translateY(20px);
    transform: translateX(-50%) translateY(20px);
    top: calc(70% + 50px);
    padding: 5px;
}
.o-tooltip-bottom-v2:before{
    position: absolute;
    content: "";
    top: -6px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border-left: 7px solid transparent;
    border-bottom: 7px solid #f18c17;
    border-right: 7px solid transparent;
}
.c-list4__compare:hover > .o-tooltip-bottom-v2 {
    opacity: 1;
    transform: translateX(-50%) translateY(-10%);
    transition: all .2s ease;
}
.o-tooltip-bottom-v3 {
    position: absolute;
    z-index: 100;
    width: 100px;    
    border-radius: 5px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .4);
    background: #f18c17;
    color: #131a31;
    font-size: 1.3rem;
    line-height: 1.2;
    text-align: center;
    font-weight: 600;
    opacity: 0;
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    transition: all .2s ease;
    left: 94%;
    -ms-transform: translateX(-50%) translateY(20px);
    transform: translateX(-50%) translateY(20px);
    top: calc(70% + 50px);
    padding: 5px;
}
.o-tooltip-bottom-v3:before{
    position: absolute;
    content: "";
    top: -6px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border-left: 7px solid transparent;
    border-bottom: 7px solid #f18c17;
    border-right: 7px solid transparent;
}
.c-list4__compare:hover > .o-tooltip-bottom-v3 {
    opacity: 1;
    transform: translateX(-50%) translateY(-10%);
    transition: all .2s ease;
}
.open{
    display:initial !important;
}
.c-list-4__box-3 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 5px;
}
.c-list-4__colors {
    display: flex;
    align-items: center;
}
.c-list-5 {
    display: -ms-flexbox;
    display: flex;
}
.c-list-5 li {
    display: block;
    width: 10px;
    height: 10px;
    margin-right: 8px !important;
    border-radius: 50%;
    background-color: #fff;
}
.c-list-5 li:last-child {
    margin-right: 0;
}
.c-list-4__price{
    color: #3e6fff;
    font-weight: 600;
    font-size: 1.9rem;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.LIST2{
    display: flex !important;
}
.LIST4{
    display: flex !important;
    min-width: 260px;
    width: 260px;
    align-items: center;
}
.LIST5{
    display: none;
}
.LIST6{
    display: flex !important;
    align-items: flex-start;
    margin-left: 15px;
}
.LIST7 {
    outline: none;
    text-decoration: none;
    color: #5d86ff;
    transition: all .2s ease;
    display: flex;
}
.c-list-4__icons-list{
    display: none;
}
@media only screen and (max-width:1500px){
    .o-tooltip-bottom-v2 {
        left: 7%;
    }
    .o-tooltip-bottom-v3 {
        left: 92%;
    }
}
@media only screen and (max-width:1200px){
    .o-tooltip-bottom-v2 {
        left: 8%;
    }
    .o-tooltip-bottom-v3 {
        left: 92%;
    }
}
@media only screen and (max-width:992px){
    .o-tooltip-bottom-v2 {
        left: 11%;
    }
    .o-tooltip-bottom-v3 {
        left: 91%;
    }
}
@media only screen and (max-width:768px){
    .c-list-4__item {
        grid-column-end: span 6;
    }
    .o-tooltip-bottom-v2 {
        left: 8%;
    }
    .o-tooltip-bottom-v3 {
        left: 93%;
    }
}
@media only screen and (max-width:600px){
    .LIST2{
        display: block !important;
    }
    .LIST6{
        display: none !important;
    }
    .LIST5{
        display: flex;
        top: 0px;
    }
    .LIST4{
        display: flex !important;
        width: 100%;
        align-items: center;
    }
    .LIST7 {
        display: block;
    }
    .o-tooltip-bottom-v2{
        left: 5%;
    }
    .o-tooltip-bottom-v3 {
        left: 95%;
    }
    .o-tooltip-bottom-v2 {
        left: 8%;
    }
    .o-tooltip-bottom-v3 {
        left: 93%;
    }
}