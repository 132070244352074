.c-block-5-p--v2 {
  min-height: calc(100vh - 239px);
  padding: 25px 0px;
  /* background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg) 100% 0; */
}
.c-block-5-p,
.c-block-5-p--v2 {
  padding-top: 20px;
  padding-bottom: 20px;
  /* background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg) 100% 0 */
}
.g-container-p {
  width: 70%;
  margin: 0 auto;
  max-width: 70%;
}
.c-nav-6-p--v2 {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 0px;
}
.c-nav-6-ep--v2 li:before {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 1.1rem;
  color: #95b0ff;
}
.o-tag-1 {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 3px 5px;
  border-radius: 5px;
  background: #3e6fff;
  color: #fff;
  line-height: 1;
  font-weight: 600;
  font-size: 1.45rem;
}
.c-block-5-p__heading-subtable {
  margin-bottom: 15px;
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
}
.c-table-2-p__img {
  display: inline-block;
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
}
.img {
  max-width: 100%;
  height: auto;
  border: none;
}
@media only screen and (max-width: 1400px) {
  .g-container-p {
    max-width: 95%;
    width: 95%;
  }
  .c-block-5-p--v2 {
    background: none;
  }
}
@media only screen and (max-width: 500px) {
  .g-container-p {
    max-width: 92%;
    width: 92%;
  }
}
