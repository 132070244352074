.c-block-2 {
  position: relative;
  padding-top: 40px;
  padding-bottom: 20px;
  margin-bottom: 60px;
  background-color: #172141;
}
.c-block-2:before {
  position: absolute;
  top: -40px;
  content: "";
  width: 100%;
  height: 370px;
}
.c-block-2:after {
  position: absolute;
  top: -45px;
  left: calc(50% + 13px);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.c-block-2__container {
  position: relative;
}
.c-block-2__heading {
  margin-bottom: 0px;
}
.c-block-2__separe {
  margin-bottom: 20px;
}
.o-heading-1,
.o-heading-huge--center {
  text-align: center;
}
.o-heading-1 {
  color: #fff;
  font-size: 2.6rem;
}
.o-text-bg {
  text-align: center;
  color: #f18c17;
  font-size: 1.9rem;
  margin-top: 0px;
}
.o-text-bg:after {
  display: block;
  content: "";
  width: 65px;
  height: 3px;
  margin: 10px auto 0;
  background: #f18c17;
}
.c-block-2__card {
  max-width: 430px;
  margin: 0 auto;
}
.c-card-1__container {
  position: relative;
  min-height: 115px;
  margin-bottom: 10px;
  padding: 18px;
  border-right: 2px solid #3e6fff;
  border-radius: 5px;
  background: #333f65;
}
.c-card-1__container:before {
  content: "";
  left: -9px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #333f65;
}
.c-card-1__container:before,
.c-card-1__img {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.c-card-1__img {
  left: -80px;
}
.c-card-1__img img {
  display: block;
  width: 54px;
}
.c-card-1__text-1 {
  font-size: 2rem;
  margin: 0px 0px;
  color: #fff;
}
.c-card-1__text-2 {
  font-size: 1.7rem;
  color: #fff;
}
.c-card-1__text-3 {
  font-size: 1.7rem;
  color: #fff;
}
.c-card-1__container:before,
.c-card-1__img {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.c-card-1__img {
  left: -130px;
}
.c-card-1__img img {
  display: block;
  width: 90px;
}
.o-btn-4--v2 {
  border: none !important;
  background: #3e6fff !important;
  color: #fff !important;
}
.o-btn-4--v2:hover {
  background: #ffffff !important;
  color: #172141 !important;
}

@media only screen and (max-width: 840px) {
  .c-block-2 {
    margin-bottom: 90px;
  }
  .c-block-2__card {
    padding-left: 100px;
  }
}
@media only screen and (max-width: 608px) {
  .c-block-2 {
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .c-block-2__card {
    padding-left: 70px;
  }
  .c-card-1__img {
    left: -70px;
  }
  .c-card-1__img img {
    width: 45px;
  }
  .c-card-1__container {
    margin-right: 10px;
    padding: 10px;
  }
  .o-heading-1 {
    font-size: 2.2rem;
  }
  .o-text-bg {
    font-size: 1.5rem;
  }
  .c-card-1__text-1 {
    font-size: 1.7rem;
  }
  .c-card-1__text-2 {
    font-size: 1.4rem;
    margin: 5px 3px;
    color: #fff;
  }
  .c-card-1__text-3 {
    font-size: 1.4rem;
    margin: 5px 0px;
    color: #fff;
  }
}
