@import url("https://use.typekit.net/uga8stg.css");

.g-container,
.g-container--grid {
  width: 70%;
  margin: 0 auto;
  max-width: 70%;
}
body {
  font-family: "myriad-pro", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.4;
  color: #a9b9ec;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 10px;
}

ol,
ul {
  list-style: none;
  padding-left: 0%;
}

a {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  vertical-align: baseline;
  text-decoration: none;
  transition: all 0.2s ease;
}

.c-body {
  background: #182140;
}

.c-body__container {
  /* min-height: 30px;  antes*/
  min-height: 0px;
  overflow-x: hidden;
  transition: all 0.2s ease;
}

.c-body__container.no-scroll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-header {
  position: fixed;
  z-index: 1200;
  width: 100%;
  height: 70px;
  background: #1b2546;
  border-bottom: 2px solid #0b0f1c;
  transition: all 0.2s ease;
}

.c-header__container {
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  height: inherit;
}

@import url("https://use.typekit.net/uga8stg.css");


.g-container--full-grid,
.g-container--grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr);
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 30px;
}

.c-header__col,
.c-header__container {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
}

.c-header__logo img {
  padding-top: 14px;
  width: 144px;
}

.c-header__main-menu {
  margin-left: 5%;
}

.c-nav-1,
.c-nav-1--v2 {
  display: -ms-flexbox;
  display: flex;
}

.nav-icons {
  margin-left: 1em;
}

#IconsRight {
  margin-left: 0px;
}

.c-nav-1--v2>li,
.c-nav-1>li {
  position: relative;
  border: 2px solid transparent;
  border-radius: 19px;
}

.c-nav-1--v2>li:last-child,
.c-nav-1>li:last-child {
  margin-right: 0;
}

.c-nav-1--v2>li>a,
.c-nav-1>li>a {
  display: flex;
  align-items: center;
  height: 33px;
  padding: 0 22px;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
  line-height: 0.8;
  width: 85%;
  transition: all 0.2s ease;
}

.Menus {
  display: flex;
  align-items: center;
  height: 33px;
  margin: 0 22px;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
  line-height: 0.8;
  width: 85%;
  cursor: pointer;
}
.Menus_mobile{
  display: block;
  padding: 13px 20px;
  color: #aabfff;
  font-weight: 600;
  font-size: 1.5rem;
  cursor: pointer;
}
.Menus_mobile:hover{
  background: #374d95;
  color: #dde5ff;
}
#mi_perfil{
  padding-top: 2px;
}
.c-nav-1--v2>li>a.active,
.c-nav-1--v2>li>a:hover,
.c-nav-1--v2>li:hover,
.c-nav-1>li>a.active,
.c-nav-1>li:hover,
.c-nav-1>li>a:hover,
.o-btn-2:hover {
  border-color: #32468a;
}

.c-nav-1--v2>li>a.activo,
.c-nav-1>li>a.activo {
  border-color: #f18c17;
  background: #f18c17;
  color: #0b0f1c;
}

.c-nav-1--v2>li>a.activo:hover,
.c-nav-1>li>a.activo:hover {
  background: #ffa216;
}

.c-nav-1--v2 {
  display: -ms-flexbox;
  display: flex;
}

.c-nav-1--v2 li a {
  position: relative;
  padding: 0 14px;
}

.c-nav-1--v2 li a.activo .c-nav-2__notify {
  background: #fff;
  border-color: #fff;
}

.c-nav-1__icon {
  margin-left: 10px;
  font-size: 1rem;
}

.c-nav-1__submenu {
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  background: #304381;
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.2s ease;
}

.c-nav-1__submenu.open {
  display: block;
  opacity: 1;
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.c-nav-1__submenu li {
  border-bottom: 1px solid #1b2546;
}

.c-nav-1__submenu li:last-child {
  border-bottom: none;
}

.c-nav-1__submenu li a {
  display: -ms-flexbox;
  display: flex;
  padding: 13px 15px;
  color: #aabfff;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.4;
}

.c-nav-1__submenu li a:hover {
  background: #374d95;
  color: #dde5ff;
}

.c-nav-1__submenu li a.activo {
  background: #3d5fcc;
  color: #fff;
}
.c-list4__compare {
  padding: 15px;
  color: #3e56a5;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s ease;
}
.c-list4__compare2,
.c-list4__wish2 {
  padding-top: 5px;
  padding-left: 15px;
  color: #3e56a5;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.2s ease;
}
.c-list-4__item:hover .c-list4__compare.active,
.c-list4__compare2:hover,
.c-list4__wish2:hover,
.c-list-4__item:hover .c-list4__compare:hover,
.c-list-4__item:hover .c-list4__wish.active,
.c-list-4__item:hover .c-list4__wish:hover {
  color: #f18c17;
}

.c-header__btns {
  margin-left: 3px;
  padding-top: 12px;
}

.c-header__btns>* {
  height: 38px;
  margin-right: 0px;
}

.c-header__btns> :last-child {
  margin-right: 0;
}

/* Botones */
.o-btn,
.o-btn-2,
.o-btn-2--v2,
.o-btn-2--v3,
.o-btn-3,
.o-btn-3--v2,
.o-btn-3--v3,
.o-btn-4,
.o-btn-4--v2,
.o-btn-4--v3,
.o-btn-7,
.o-btn-7--v2 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: 0;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  border-radius: 20px;
  border: 2px solid #f18c17;
  background: transparent;
  font-size: 1.5rem;
  color: #f18c17;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
  margin-left: 4px;
}

.o-btn-2--v2:hover,
.o-btn-2--v3:hover,
.o-btn-3--v2:hover,
.o-btn-3--v3:hover,
.o-btn-3:hover,
.o-btn-4--v2:hover,
.o-btn-4--v3:hover,
.o-btn-4:hover,
.o-btn-7--v2:hover,
.o-btn-7:hover,
.o-btn:hover {
  background: #f18c17;
  color: #0b0f1c;
}

.o-btn-2 .icon-iz,
.o-btn-2--v2 .icon-iz,
.o-btn-2--v3 .icon-iz,
.o-btn-3 .icon-iz,
.o-btn-3--v2 .icon-iz,
.o-btn-3--v3 .icon-iz,
.o-btn-4 .icon-iz,
.o-btn-4--v2 .icon-iz,
.o-btn-4--v3 .icon-iz,
.o-btn-7 .icon-iz,
.o-btn-7--v2 .icon-iz,
.o-btn .icon-iz {
  margin-right: 10px;
  font-size: 1.7rem;
}

.o-btn-2 .icon-iz-v2,
.o-btn-2--v2 .icon-iz-v2,
.o-btn-2--v3 .icon-iz-v2,
.o-btn-3 .icon-iz-v2,
.o-btn-3--v2 .icon-iz-v2,
.o-btn-3--v3 .icon-iz-v2,
.o-btn-4 .icon-iz-v2,
.o-btn-4--v2 .icon-iz-v2,
.o-btn-4--v3 .icon-iz-v2,
.o-btn-7 .icon-iz-v2,
.o-btn-7--v2 .icon-iz-v2,
.o-btn .icon-iz-v2 {
  margin-right: 10px;
}

.o-btn-2 .icon-der,
.o-btn-2--v2 .icon-der,
.o-btn-2--v3 .icon-der,
.o-btn-3 .icon-der,
.o-btn-3--v2 .icon-der,
.o-btn-3--v3 .icon-der,
.o-btn-4 .icon-der,
.o-btn-4--v2 .icon-der,
.o-btn-4--v3 .icon-der,
.o-btn-7 .icon-der,
.o-btn-7--v2 .icon-der,
.o-btn .icon-der {
  margin-left: 10px;
  font-size: 1.7rem;
}

.o-btn-2,
.o-btn-2--v2,
.o-btn-2--v3 {
  border-color: transparent;
  color: #fff;
}

/* #header-comparador-sticky-wrapper.is-sticky .c-list-4__img, .c-header__btns {
    display: none;
} */

.o-btn-2--v2 {
  border-color: hsla(0, 0%, 100%, 0.3);
}

.o-btn-2--v2:hover {
  border-color: #fff;
}

.c-header__btn-mobile {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 17px 20px 0px 20px;
  font-size: 2.3rem;
  color: #fff;
  padding-top: 1px;
  cursor: pointer;
}

.c-header__btn-mobile {
  display: none;
}

.c-body__open-filtros .c-header__btn-mobile {
  display: -ms-flexbox;
  display: flex;
}

.c-nav-mobile {
  position: fixed;
  z-index: 102;
  top: 0;
  right: 0;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: 100vh;
  padding-top: 70px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  background: #2d3e75;
  -ms-transform: translateX(315px);
  transform: translateX(315px);
  transition: all 0.2s ease;
}

.c-nav-mobile.open {
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.c-body__open-filtros .c-nav-mobile.open {
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

.c-nav-mobile__container {
  width: 100%;
  position: relative;
  overflow-y: scroll;
  overflow-y: hidden;
}

.c-nav-mobile__container:before {
  display: block;
  content: "";
  width: 100%;
  height: 5px;
  background: #3e6fff;
}

.c-nav-mobile__btns {
  border-bottom: 1px solid #0b0f1c;
  padding: 12px 20px;
  background: #273666;
  display: none;
}

.c-nav-mobile__btns>* {
  width: calc(50% - 2.5px);
  margin-right: 5px;
}

.c-nav-mobile__btns> :last-child {
  margin-right: 0;
}

.c-nav-3 {
  margin-top: 0px;
}

.c-nav-3>li {
  border-bottom: 1px solid #1b2546;
}

.c-nav-3>li:last-child {
  border-bottom: none;
}

.c-nav-3>li>a {
  display: block;
  padding: 13px 20px;
  color: #aabfff;
  font-weight: 600;
  font-size: 1.5rem;
}

.c-nav-3>li>a:hover {
  background: #374d95;
  color: #dde5ff;
}

.c-nav-3__icon {
  margin-left: 5px;
  font-size: 1.1rem;
}

.c-nav-3__submenu {
  display: none;
}

.c-nav-3__submenu.open {
  display: block;
}

.c-nav-3__submenu li a {
  display: block;
  padding: 12px 20px 12px 40px !important;
  color: #aabfff;
  font-weight: 600;
  font-size: 1.5rem;
}

.MenusMob {
  display: block;
  padding: 13px 20px;
  color: #aabfff;
  font-weight: 600;
  font-size: 1.5rem;
}

.c-nav-2__notify {
  position: absolute;
  top: -3px;
  right: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 1px solid #1b2546;
  background: #f18c17;
  font-size: 1.1rem;
  font-weight: bold;
  color: #0b0f1c;
  transition: all 0.2s ease;
}

.c-nav-3__submenu li a {
  display: block;
  padding: 12px 20px 12px 20px;
  color: #aabfff;
  font-weight: 600;
  font-size: 1.5rem;
}

.c-nav-3__submenu li a:hover {
  background: #374d95;
  color: #dde5ff;
}

@keyframes a {
  0% {
    transform: scale(1);
  }

  15% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale(2.5);
  }
}

/* responsive */
@media only screen and (max-width: 1400px) {
  .g-container,
  .g-container--grid {
    max-width: 95%;
    width: 95%;
  }
}

@media only screen and (max-width: 1020px) {

  /* .g-container,
  .g-container--grid {
    max-width: 900px;
    width: calc(100% - 40px);
    margin-right: 20px;
    margin-left: 20px;
  } */

  .c-nav-1 {
    display: none;
  }

  .c-header__btn-mobile {
    display: block;
  }

  .colm2 {
    margin-left: 170px;
  }

  .c-nav-mobile {
    display: block;
  }

  .c-header__logo img {
    padding-top: 4px;
  }
}

@media only screen and (max-width: 774px) {

  /* .g-container,
  .g-container--grid {
    width: calc(100% - 40px);
    margin-right: 20px;
    margin-left: 20px;
  }

  .g-container,
  .g-container--grid {
    max-width: 780px;
  } */

  .c-header__btns {
    display: none;
  }

  .nav-icons {
    margin-left: 0.5em;
  }

  .c-nav-1--v2>li>a {
    width: auto;
  }

  .c-nav-3 {
    margin: 0%;
  }

  .o-btn-2--v2,
  .o-btn {
    width: 60px;
  }

  .o-btn-2--v2:hover {
    border-color: hsla(0, 0%, 100%, 0.9);
    color: #ffffff;
    background-color: #273666 !important;
  }

  .c-nav-mobile__btns {
    text-align: center !important;
    display: flow-root;
  }

}

@media only screen and (max-width: 490px) {
  .c-nav-2 li {
    margin: 5 15px;
  }

  .colm2,
  .c-header__btn-mobile {
    margin-left: 10px !important;
  }

}

.c-header-m__btns {
  margin-left: 5px;
}

.c-header-m__btns > :last-child {
  margin-right: 0;
}
.c-nav-1-m,
.c-nav-1-m--v2 {
  display: -ms-flexbox;
  display: flex;
}
.c-header-m__btns > * {
  height: 38px;
  margin-right: 5px;
}
ol,
ul {
  list-style: none;
}


.c-nav-1-m--v2>li:last-child, .c-nav-1-m>li:last-child {
  margin-right: 0;
}
.c-nav-1-m--v2>li, .c-nav-1-m>li {
  position: relative;
  margin-right: 5px;
}

.c-nav-1-m--v2>li>a, .c-nav-1-m>li>a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 38px;
  padding: 0 22px;
  border-radius: 19px;
  border: 2px solid transparent;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 600;
  line-height: .8;
}
a {
  outline: none;
  text-decoration: none;
  color: #5d86ff;
  transition: all .2s ease;
}

.c-nav-1-m__icon {
  margin-left: 10px;
  font-size: 1rem;
}

.icon-arrow-down3:before {
  content: "\edc1";
}

.c-nav-1-m__submenu, .c-nav-1-m__submenu--v2, .c-nav-1-m__submenu--v3 {
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  overflow: hidden;
  width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
  background: #304381;
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  transition: all .2s ease;
}

.c-nav-1-m__submenu--v2 li, .c-nav-1-m__submenu--v3 li, .c-nav-1-m__submenu li {
  border-bottom: 1px solid #1b2546;
}

.c-nav-1-m__submenu--v2 li a, .c-nav-1-m__submenu--v3 li a, .c-nav-1-m__submenu li a {
  display: -ms-flexbox;
  display: flex;
  padding: 13px 15px;
  color: #aabfff;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.4;
}