.css-6hp17o-MuiList-root-MuiMenu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;
  outline: 0;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgba(55, 77, 149) !important;
}

