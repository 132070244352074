.c-block-3,
.c-block-3--v2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 70px;
  padding-top: 70px;
  border-bottom: 2px solid #3e6fff;
  background-image: url(https://www.supre.com.co/images/bg-slider-1.ccb0fccaca.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.c-block-3--v2 {
  border-bottom: none;
}
.o-heading-1,
.o-heading-huge--center {
  text-align: center;
}
.o-heading-1 {
  color: #fff;
  font-size: 3.2rem !important;
}

@media only screen and (min-width: 1200px) {
  /* .g-container,
  .g-container--grid {
    max-width: 1134px;
  } */
}

@media only screen and (min-width: 992px) {
  /* .g-container,
  .g-container--grid {
    max-width: 970px;
  } */
}

@media only screen and (max-width: 608px) {
  /* .g-container,
  .g-container--grid {
    max-width: 750px;
    
  } */
  /*.c-block-3  {margin-top: 2rem;}*/
  .o-heading-1 {
    font-size: 2.2rem !important;
  }
}

/* .g-container,
.g-container--grid {
  width: 100%;
  margin: 0 auto !important;
} */
