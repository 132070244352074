.g-containerPC{
    width: 1134px;
    margin: auto;
}
.c-block-5__heading-6 {
    margin-bottom: 2px;
    padding-bottom: 3px;
    color: #f18c17;
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
}
.c-block-5__pc {
    padding-top: 10px;
    border-top: 1px solid #f18c17;
    text-align: center;
    font-size: 2rem;
    color: #fff;
    margin-top: 0px;
}
.g-rowPC {
    grid-template-columns: repeat(12, 1fr);
    display: grid;
    grid-column-gap: 30px;
}
.g-col-xl-4PC{
    grid-column-end: span 4 !important;
}
.c-table-2--v2 {
    display: block;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    background: #1e2a52;
    color: #a9b9ec;
    font-size: 1.6rem;
    padding: 20px;
    font-size: 1.7rem;
}
.c-table-2__header {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #3e6fff;
    font-size: 1.8rem;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 600;
    color: #fff;
}
.number, .number--movil{
    text-align: center;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    border-radius: 50%;
    background: #3e6fff;
}
.number--movil{
    display: none;
}
/* parte de los puntos de recaudo (exportar en un atomo) */
.c-list-3__item {
    break-inside: avoid;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px;
}
.c-list-3__heading{
    font-weight: 600;
    font-size: 1.7rem;
}
.c-list-3__iconPC{
    width: 20px;
    margin-right: 15px;
    color: #f18c17;
    font-size: 1.8rem;
}   
/*Parte de aliados de recaudo*/
.c-list-3__item-2{
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px;
}
.c-list-3__logo{
    margin-right: 15px;
}
.c-list-3__logo img {
    display: block;
    border-radius: 5px;
    width: 120px;
}
.c-list-3__info-1 {
    color: #fff;
    font-weight: 600;
    font-size: 1.8rem;
}
.c-list-3__info-2{
    font-size: 1.4rem;
}
@media only screen and (max-width: 1200px){
    .g-containerPC {
    max-width: 95%;
    width: 95%;
  }
}
@media only screen and (max-width: 1100px){
    .g-containerPC{
        width: 750px;
    }
    .c-block-4__col-pagos-1 {
        order: 3;
     }
     .c-block-4__col-pagos-2 {
         order: 2;
     }
     .c-block-4__col-pagos-3 {
         order: 1;
     }
    .g-col-xl-4PC{
        grid-column-end: span 6 !important;
    }
    .g-containerPC{
        width: 750px;
        margin: auto;
    }
    .number{
        display: none;
    }     
    .number--movil{
        display: block;
    }
}
@media only screen and (max-width: 700px){
    .g-col-xl-4PC{
        grid-column-end: span 12 !important;
    }
    .g-containerPC {
        max-width: 450px;
        width: 90%;
      }
}