.QS {
  background-image: url(https://www.supre.com.co/images/header-quienes-somos.jpg) !important;
}
.g-containerQS {
  max-width: 70%;
  margin: auto;
  margin-bottom: 20px;
}
.c-block-5--v2 {
  min-height: calc(100vh - 239px);
  padding: 20px 0px;
  background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg)
      100% 0 no-repeat,
    url(https://www.supre.com.co/images/bg-block-4-iz.9291ce49c3.svg) 0 100%
      no-repeat;
}
.c-card-2 {
  position: relative;
  padding-left: 280px;
  border-radius: 5px;
  margin-left: 100px;
  border: 1px solid #0e1327;
  background: #1e2a52;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  margin-bottom: 60px;
}
.c-card-2 p {
  line-height: 1.5;
}
.c-card-2 strong {
  font-weight: 600;
}
.c-card-2__img img {
  position: absolute;
  top: 50%;
  left: -100px;
  transform: translateY(-50%);
  max-width: 330px;
  display: block;
  border-radius: 50%;
  border: 1px solid #3e6fff;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);
}
.o-heading-3 {
  text-align: center;
  color: white;
  font-size: 2.8rem;
}
.g-row {
  -ms-grid-columns: (1fr);
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 54px;
  margin-bottom: 58px;
  display: -ms-grid;
  display: grid;
}
.col-l-6-mision {
  text-align: right;
  grid-column-start: 1;
  grid-column-end: 7;
}
.col-l-6-vision {
  text-align: left;
  grid-column-start: 7;
  grid-column-end: 13;
}
@media only screen and (max-width: 1400px) {
  .c-block-5--v2 {
    background: none;
  }
  .g-containerQS {
    margin: auto;
    max-width: 95%;
    width: 95%;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 800px) {
  .c-card-2 {
    position: none;
    padding: 10px 30px;
    margin-left: 0px;
  }
  .c-card-2__img {
    margin-bottom: 30px;
  }
  .c-card-2__img img {
    position: inherit;
    transform: translateY(0%);
    width: 200px;
    margin: 0 auto;
  }
  .g-row {
    grid-column-gap: 0px;
  }
  .col-l-6-mision {
    text-align: right;
    grid-column-start: 1;
    grid-column-end: 13;
  }
  .col-l-6-vision {
    text-align: left;
    grid-column-start: 1;
    grid-column-end: 13;
  }
}
@media only screen and (max-width: 490px) {
  .c-card-2 strong {
    font-weight: 450;
  }
  .c-card-2__img {
    margin-bottom: 10px;
  }
  .c-card-2__img img {
    position: inherit;
    transform: translateY(0%);
    width: 200px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1200px) {
  .g-containerQS {
    max-width: 970px;
    margin: auto;
    margin-bottom: 20px;
  }
  .g-row {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 992px) {
  .g-containerQS {
    max-width: 810px;
    margin: auto;
    margin-bottom: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .g-containerQS {
    width: calc(100% - 40px);
    margin-right: 20px;
    margin-left: 20px;
  }
  .c-card-2 {
    position: none;
    padding: 10px 30px;
    margin-left: 0px;
  }
  .c-card-2__img {
    margin-bottom: 30px;
  }
  .c-card-2__img img {
    position: inherit;
    transform: translateY(0%);
    width: 200px;
    margin: 0 auto;
  }
  .g-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .c-block-5--v2 {
    background: none;
  }
  .g-col-l-6 {
    text-align: left !important;
  }
  .g-row {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 490px) {
  .c-card-2 strong {
    font-weight: 450;
  }
  .c-card-2__img {
    margin-bottom: 10px;
  }
  .c-card-2__img img {
    position: inherit;
    transform: translateY(0%);
    width: 200px;
    margin: 0 auto;
  }
  .g-row {
    margin-bottom: 0px;
  }
}
