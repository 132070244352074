.body-sign {
  display: table;
  height: 100vh;
  margin: 0 auto;
  max-width: 500px;
  padding: 0 15px;
  width: 100%;
}
.body-sign .center-sign {
  display: table-cell;
  padding-top: 20px;
  vertical-align: middle;
}
.body-sign .card-sign {
  background: transparent;
  min-height: 370.922px;
}
.text-right {
  text-align: right !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.float-left {
  float: left !important;
}
.card-body a {
  color: #0088cc !important;
}
img {
  vertical-align: middle;
  border-style: none;
}
.body-sign .card-sign .card-title-sign .title {
  background-color: #0088cc !important;
}
.body-sign .card-sign .card-title-sign .title {
  background-color: #ccc;
  border-radius: 5px 5px 0 0;
  color: #fff;
  display: inline-block;
  font-size: 12px !important;
  padding: 19px 17px;
  vertical-align: bottom;
  margin: 0;
}
.font-weight-bold,
b,
strong {
  font-weight: 700 !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.text-6 {
  font-size: 1.8em !important;
}
.top-5 {
  top: 5px !important;
}
.body-sign .card-sign .card-body {
  border-top-color: #0088cc !important;
  /* min-height: 302.922px; */
}
.body-sign .card-sign .card-body {
  background: #fff;
  border-top: 5px solid #ccc;
  border-radius: 5px 0 5px 5px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  padding: 33px 33px 15px;
}
.card-body {
  background: #fff;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  border-radius: 5px;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation)
  > .custom-file:not(:last-child)
  .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-group + .form-group {
  border-top: 1px solid #f3f3f3;
  padding-top: 16px;
  padding-top: 1rem;
}
.text-muted {
  color: #999 !important;
}

.card-body .btn-primary {
  background-color: #0088cc;
  border-color: #0088cc #0088cc #006699;
  color: #fff;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.card-body label {
  height: 22px;
  color: rgb(119, 119, 119) !important;
  font-size: 13px;
}

.card-body button {
  height: 38.6406px;
  width: 119.484px;
  padding: 7.52px 12px;
  font-size: 14.4px;
  border-radius: 5px;
}

.body-sign input {
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
}
.text-center {
  text-align: center !important;
}

.logo-admin-supre{
  width: 117px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 20px;
}