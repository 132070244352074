
.c-form-3__finish {
    width: 100%;
    padding: 25px 0px;
    border-radius: 5px;
    background: #1e2a52;
    border-top: 3px solid #f18c17;
}
.c-form-3_cont-check {
    margin-top: 40px;
    text-align: center;
    align-items: center;
}
.c-form-3_check {
    color: #f18c17;
    font-size: 5rem;
}
.c-form-3__heading_finish {
    text-align: center;
    font-size: 3.5rem;
    margin: 10px;
    color: #ffff
}
.c-form-3__p {
    text-align: center;
    font-size:  1.8rem;
    margin: 0px 0px 40px 0px;
}
.c-form-3__btns {
    padding: 20px 0 20px 0;
    text-align: center;
    align-items: center;
}