.c-block-4 {
  padding-top: 60px;
  padding-bottom: 60px;
  background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg)
      100% 0 no-repeat,
    url(https://www.supre.com.co/images/bg-block-4-iz.9291ce49c3.svg) 0 100%
      no-repeat;
}
.g-container-rq {
  width: 70%;
  margin: 0 auto;
  max-width: 70%;
}
.c-block-4__col--moto {
  grid-column-end: span 5;
  display: flex;
  position: relative;
}
.c-block-4__col--moto:before {
  content: "";
  position: absolute;
  top: 400px;
  width: 430px;
  height: 243px;
  right: 20px;
  background-image: url(https://www.supre.com.co/images/bg-moto.6bf31782a5.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.c-block-4__col {
  grid-column-end: span 7;
  display: flex;
}
@media only screen and (max-width: 1400px) {
  .g-container-rq {
    max-width: 95%;
    width: 95%;
  }
  .c-block-4 {
    background: none;
  }
}
@media only screen and (max-width: 1200px) {
  .c-block-4__col--moto:before {
    right: 0px;
  }
}

@media only screen and (max-width: 1100px) {
  .c-block-4__col--moto:before {
    display: none;
  }
  .c-block-4__box {
    padding-top: 0px;
  }
  .c-block-4__container {
    grid-column-gap: 0px;
    display: inline;
  }
}
