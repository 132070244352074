/* Quitar la parte del incremento de la derecha */

input[type=number] {
    -moz-appearance: textfield;
}
input::-webkit-inner-spin-button, input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/*  importacion de las fonts de iconos  */
@font-face {
    font-family: "icomoon";
    src: url(../../../fonts/icomoon.woff2) format("woff2"), url(../../../fonts/icomoon.woff) format("woff");
}

.c-block-4__col--frcc {
    -ms-grid-column-span: 6;
    grid-column-end: span 6;
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    padding: 0px 20px;
}
.c-block-4__col--frcc:last-child {
    padding-left: 20px;
}
.c-form-ct {
    width: 100%;
    padding: 25px 20px;
    border-radius: 5px;
    border: 1px solid #0e1327;
    background: #1e2a52;
}
.c-form-ct__row {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr);
    grid-template-columns: repeat(12,1fr);
    grid-column-gap: 30px;
}
.c-form-ct__field {
    -ms-grid-column-span: 6;
    grid-column-end: span 6;
    margin-bottom: 15px;
    width: 100%;
}
.o-form-label {
    display: block;
    margin-bottom: 6px;
    color: #a9b9ec;
    font-weight: 600;
    text-align: center;
}
.o-form-fieldC1, .o-form-field--textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 92%;
    height: 40px;
    padding: 0 15px;
    border: 1px solid #0c1225;
    border-radius: 5px;
    outline: 0;
    background: #283768;
    font-size: 1.6rem;
    font-weight: 400;
    color: #758fe5;
    transition: all .3s ease;
}
.o-form-fieldC{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 84%;
    height: 40px;
    padding: 0 15px;
    border: 1px solid #0c1225;
    border-radius: 5px;
    outline: 0;
    background: #283768;
    font-size: 1.6rem;
    font-weight: 400;
    color: #758fe5;
    transition: all .3s ease;
}
.o-form-fieldC2{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 84%;
    height: 40px;
    padding: 0 15px;
    border: 1px solid #0c1225;
    border-radius: 5px;
    outline: 0;
    background: #283768;
    font-size: 1.6rem;
    font-weight: 400;
    color: #758fe5;
    transition: all .3s ease;
    margin-top: 0px !important;
}
.o-form-fieldC:focus, .o-form-field:focus, .o-form-field--textarea:focus{
    border: 1px solid #2f58e2;
}
.o-form-field--textarea {
    height: 150px;
}
.c-form-ct__btn {
    display: flex;
    justify-content: center;
}
.o-btn-3--v2 {
    border: none !important;
    background: #f18c17 !important;
    color: #0b0f1c !important;
    padding: 0 38px !important;
}
.o-btn-3--v2:hover {
    border: none;
    background: white !important;
    color: #0b0f1c !important;
    padding: 0 38px !important;
}
.o-link-1{
    color:#f18c17;
}
.o-link-1:hover{
    color:#ffffff;
}
/* icono check */
.o-form-check {
    display: -ms-flexbox;
    display: flex;
}
.o-form-check__campo, .o-form-check__campo--v2 {
    position: absolute;
    visibility: hidden;
}
.o-form-check__campo+label, .o-form-check__campo+span+label, .o-form-check__campo--v2+label, .o-form-check__campo--v2+span+label {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 7px;
    font-family: "icomoon"!important;
    font-size: 2rem;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #3e6fff;
    cursor: pointer;
    transition: all .2s ease;
    margin-top: 4px;
}
.o-form-check__campo+label+span, .o-form-check__campo+span+label+span, .o-form-check__campo--v2+label+span, .o-form-check__campo--v2+span+label+span {
    padding-top: 2px;
    font-size: 1.5rem;
    color: #a9b9ec;
    font-weight: 500;
}
.o-form-check__campo+label span, .o-form-check__campo+span+label span, .o-form-check__campo--v2+label span, .o-form-check__campo--v2+span+label span {
    -ms-flex: 1;
    flex: 1;
    margin-left: 7px;
    padding-top: 2px;
    font-size: 1.5rem;
    line-height: 1.3;
    color: #a9b9ec;
    font-weight: 500;
    font-family: "myriad-pro", sans-serif;
}
.o-form-check__campo+label:hover, .o-form-check__campo+span+label:hover, .o-form-check__campo--v2+label:hover, .o-form-check__campo--v2+span+label:hover {
    color: #fff;
}
.o-form-check__campo--v2[type=checkbox]+label:before, .o-form-check__campo--v2[type=checkbox]+span+label:before, .o-form-check__campo[type=checkbox]+label:before, .o-form-check__campo[type=checkbox]+span+label:before {
    content: "\ee6f"}
.o-form-check__campo--v2[type=radio]+label:before, .o-form-check__campo--v2[type=radio]+span+label:before, .o-form-check__campo[type=radio]+label:before, .o-form-check__campo[type=radio]+span+label:before {
    content: "\ee73"}
.o-form-check__campo--v2:checked+label, .o-form-check__campo:checked+label {
    color: #f18c17;
}
.o-form-check__campo--v2:checked+label:hover:before, .o-form-check__campo:checked+label:hover:before {
    color: #dc7c0d;
}
.o-form-check__campo--v2:checked[type=checkbox]+label:before, .o-form-check__campo:checked[type=checkbox]+label:before {
    content: "\ee6e"}
.o-form-check__campo--v2:checked[type=radio]+label:before, .o-form-check__campo:checked[type=radio]+label:before {
    content: "\ee76"}
.o-form-check__campo--v2+label, .o-form-check__campo--v2+span+label {
    font-size: 1.7rem;
    color: #a9b9ec;
}
.o-form-check__campo--v2+label+span, .o-form-check__campo--v2+label span, .o-form-check__campo--v2+span+label+span, .o-form-check__campo--v2+span+label span {
    padding-top: 0;
}
.c-notify--alert_Requeriments{
    background: #ecea59;
    color: #161e38;
    display: flex;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 1.5rem;
    font-weight: 500;
}
.c-notify--alert_form-pago{
    margin-top: 12px;
    background: #fcfcfa;
    border: 1px solid red;
    color: rgb(248, 43, 6);
    display: flex;
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    font-size: 1.3rem;
    font-weight: 600 !important;
}
@media only screen and (max-width: 1100px){
    .o-form-fieldC1, .o-form-field--textarea {
        width: 96%;
    }
    .o-form-fieldC{
        width: 92%;
    }
}
@media only screen and (max-width: 840px){
    .o-form-fieldC1, .o-form-field--textarea {
        width: 95%;
    }
    .o-form-fieldC{
        width: 90%;
    }
}
@media only screen and (max-width: 500px){
    .o-form-fieldC1, .o-form-field--textarea {
        width: 91%;
    }
    .o-form-fieldC{
        width: 91%;
    }
    .c-form-ct__row{
        grid-template-columns: none;
    }
    .o-form-label {
        text-align: left;
    }
}