.c-modal-2 {
    position: fixed;
    z-index: 101;
    top: 50%;
    right: 0;
    -ms-transform: translateY(-50%) translateX(160px);
    transform: translateY(-50%) translateX(160px);
    width: 140px;
    border: 1px solid #304178;
    border-radius: 5px 0 0 5px;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, .75);
    background: #25325d;
    transition: all .2s ease;
}
.c-modal-2.open {
    -ms-transform: translateY(-50%) translateX(0);
    transform: translateY(-50%) translateX(0);
}
.c-modal-2__close {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px 0 0 0;
    border-bottom: 1px solid #1a2340;
    font-size: 1.5rem;
    font-weight: 600;
    color: #a9b9ec;
    cursor: pointer;
    transition: all .2s ease;
}
.c-modal-2__close:hover {
    background: #1f2a4f;
}
.icon-arrow-right8 {
    height: 18px;
    margin-left: 8px;
}

.c-list-11__item {
    border-top: 1px solid #304178;
    border-bottom: 1px solid #1a2340;
}
.c-list-11__item img {
    display: block;
    max-width: 100%;
    height: auto;
    border: none;
}
.c-list-11__img {
    position: relative;
    padding: 7px 15px;
    transition: all .2s ease;
}
.c-list-11__img.hide {
    -ms-transform: scale(.6);
    transform: scale(.6);
    opacity: 0;
}
.c-list-11__icons-grid {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 90%;
    padding: 8px;
    transition: all .2s ease;
}
.c-list-11__close {
    font-size: 1.7rem;
    color: #f18c17;
    cursor: pointer;
    transition: all .2s ease;
}
.c-list-11__close:hover {
    color: #fff;
}
.c-modal-2__msj {
    display: block;
    padding: 10px 15px;
    color: #ff7575;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
}
.c-modal-2__btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 50px;
    border-radius: 0 0 0 5px;
    background: #f18c17;
    color: #0f1425;
    font-weight: 600;
}
.c-modal-2__btn:hover {
    background: #fff;
    color: #0f1425;
}