.c-block-4-r {
  padding-top: 40px;
  padding-bottom: 20px;
  background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg)
      100% 0 no-repeat,
    url(https://www.supre.com.co/images/bg-block-4-iz.9291ce49c3.svg) 0 100%
      no-repeat;
}
.g-container-r,
.g-container-r--grid {
  width: 100%;
  margin: 0 auto;
}
.c-block-4__box-4-r {
  max-width: 100%;
  margin: 0 auto;
}
.c-form-1-r__heading {
  margin-bottom: 20px;
  padding-bottom: 2px;
  border-bottom: 1px solid #f18c17;
  color: #f18c17;
  font-weight: 600;
  text-align: center;
  font-size: 1.8rem;
}
.c-form-1-r,
.c-form-3-r {
  margin: 0 auto;
  padding: 20px 15px;
  border-radius: 5px;
  border: 1px solid #0e1327;
  background: #1e2a52;
}
.c-form-1-r__row {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 30px;
}
.c-form-1-r__field {
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  margin-bottom: 20px;
}
.o-form-label-r {
  display: block;
  margin-bottom: 6px;
  color: #a9b9ec;
  font-weight: 600;
  text-align: center;
}

.o-form-field-r,
.o-form-field-r--text-center,
.o-form-field-r--textarea,
.o-form-field-r--v2,
.selectize-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 98%;
  height: 40px;
  padding: 0 0 0 5px;
  border: 1px solid #0c1225;
  border-radius: 5px;
  outline: 0;
  background: #283768;
  font-size: 1.6rem;
  font-weight: 400;
  color: #758fe5;
  transition: all 0.3s ease;
}

.o-form-check-r {
  display: -ms-flexbox;
  display: flex;
}

.o-is-hidden-2-r {
  display: none;
}

.c-notify-r--error:last-child,
.c-notify-r--ok:last-child,
.c-notify-r--primary:last-child,
.c-notify-r--secondary:last-child,
.c-notify-r:last-child {
  margin-bottom: 0;
}

.c-notify-r--error {
  background: #f9b7b7;
  color: #7b0b0b;
}
.c-notify-r,
.c-notify-r--error,
.c-notify-r--ok,
.c-notify-r--primary,
.c-notify-r--secondary {
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #fff;
  font-size: 1.5rem;
  font-weight: 500;
}
.c-form-1-r__btn {
  display: flex;
  justify-content: center;
}
.o-btn-3-r,
.o-btn-3-r--v2,
.o-btn-3-r--v3 {
  border: none;
  background: #f18c17;
  color: #0b0f1c;
}

.c-modal-1__box-2-r {
  padding-top: 10px;
  text-align: center;
}

.o-text-6-r {
  font-weight: 600;
  color: #fff;
}
@media only screen and (max-width: 1400px) {
  .c-block-4-r {
    background: none;
  }
}
@media only screen and (min-width: 1200px) {
  .g-container-r,
  .g-container-r--grid {
    max-width: 1334px;
  }
}
@media only screen and (min-width: 992px) {
  .g-container-r,
  .g-container-r--grid {
    max-width: 970px;
  }
}
@media only screen and (min-width: 769px) {
  .g-container-r,
  .g-container-r--grid {
    max-width: 750px;
  }
}
@media only screen and (max-width: 768px) {
  .g-container-r,
  .g-container-r--grid {
    width: calc(100% - 40px) !important;
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 500px) {
  .c-form-1-r,
  .c-form-3-r {
    padding: 25px 20px;
  }
  .c-form-1-r__row {
    grid-template-columns: repeat(12, 1fr) !important;
  }
}
@media only screen and (max-width: 500px) {
  .o-form-label-r {
    text-align: left;
  }
}
