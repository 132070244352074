.c-list-imgCar__item {
    background: #2746a1;
}
.c-list-imgCar__item:nth-child(2n) {
    background: #1c357f;
}
.c-list-imgCar__box {
    display: flex;
    padding: 10px 25px;
}
.c-list-12__imgCar, .c-list-12__imgCar--v2 {
    display: -ms-flexbox;
    display: flex;
}
.c-list-12__imgCar--v2:last-child, .c-list-12__imgCar:last-child {
    padding-top: 0;
}
.c-list-12__imgCar--v2 {
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px 30px;
}
.c-list-12__imgCar-2 {
    -ms-flex: 1;
    flex: 1;
    display: -ms-flexbox;
    display: flex;
}
.c-list-12__imgCar {
    width: 100px;
    padding: 10px 0;
    margin-right: 15px;
    border-radius: 5px;
    background: #fff;
}
.c-list-12__imgCar-2 {
    -ms-flex: 1;
    flex: 1;
    display: -ms-flexbox;
    display: flex;
}
.c-list-12__info-imgCar {
    -ms-flex: 1;
    flex: 1;
}
.c-list-12__marca-imgCar {
    font-weight: 600;
    font-size: 1.6rem;
}
.c-list-12__name-imgCar {
    color: #fff;
    font-weight: 600;
    font-size: 2.1rem;
    line-height: 1.2;
}
.c-list-12__modelo-imgCar {
    color: #fff;
    font-size: 1.4rem;
}
.c-list-12__delete-imgCar {
    margin: 0px 5px 0px 20px;
    color: #f18c17;
    font-size: 2rem;
    cursor: pointer;
    transition: all .2s ease;
}
.c-list-12__delete-imgCar:hover {
    color: #fff;
}
.c-list-7-imgCar {
    display: -ms-flexbox;
    display: flex;
}
.c-list-7__item-imgCar {
    margin-right: 13px;
}
.c-list-7__item-imgCar:last-child {
    margin-right: 0;
}
.c-list-12__price-imgCar {
    margin-left: 15px;
    color: #fff;
    font-weight: 600;
    font-size: 2rem;
}
.o-form-check-imgCar {
    display: flex;
    justify-content: flex-end;
    padding: 1px 31px 10px 0px;
}
.check-terminoscotizador {
    margin-top: 5px;
}
.o-form-check__campo+label {
    font-size: 1.5rem;
}
.o-form-check__campo+label+span {
    font-size: 1.3rem;
}
.c-form-1__text-matricula {
    margin: 2px 0px 0px 3px;
    font-size: 1.3rem;
    font-weight: 600;
}
@media only screen and (max-width:430px) {
    .c-list-imgCar__box {
        padding: 10px 10px;
    }
    .c-list-12__modelo-imgCar {
        font-size: 1.3rem;
    }
    .c-list-12__name-imgCar {
        font-size: 1.8rem;
    }
    .c-list-12__modelo-imgCar {
        font-size: 1.2rem;
    }
    .c-list-12__delete-imgCar {
        font-size: 2rem;
    }
    .c-list-12__imgCar--v2 {
        padding: 0px 17px;
    }
    .c-list-12__price-imgCar {
        font-size: 1.8rem;
    }
    .o-form-check-imgCar {
        display: flex;
        justify-content: flex-end;
        padding: 0px 17px 10px 0px;
    }
    .check-terminoscotizador {
        margin-top: 4px;
    }
}