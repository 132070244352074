.o-btn-whatsapp {
  position: fixed;
  z-index: 100;
  bottom: 60px;
  right: 20px;
}
.o-btn-whatsapp img {
  position: relative;
  z-index: 10;
}
.o-btn-whatsapp__onda {
  position: absolute;
  z-index: 1;
  top: 1px;
  left: 4px;
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #00e676;
  opacity: 0;
  animation: a 1.7s infinite;
}
.onda1 {
  animation-delay: 1s;
}
.onda2 {
  animation-delay: 1.3s;
}
.onda3 {
  animation-delay: 1.5s;
}
@keyframes a {
  0% {
    transform: scale(1);
  }
  15% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(2.5);
  }
}
