.o-form-field-pc {
    width: 100% !important;
    height: 39px;
    padding: 0px 0px 0px 5px;
    border: 1px solid #0c1225;
    border-radius: 5px;
    outline: 0;
    background: #283768;
    font-size: 1.6rem;
    font-weight: 400;
    color: #758fe5;
    transition: all .3s ease;
}
.o-form-field-pc {
    color: #fff;
}
.o-form-field-pc:focus {
    border: 1px solid #2f58e2;
}
.o-btn-pc {
    display: flex;
    justify-content: center;
}