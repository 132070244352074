.c-block-6 {
  background: url(https://www.supre.com.co/images/bg-block-4-der.da0a57fcd4.svg)
      100% 0 no-repeat,
    url(https://www.supre.com.co/bg-block-4-iz.9291ce49c3.svg) 0 100% no-repeat;
  min-height: calc(100vh - 199px);
}
.c-block-6__tags-filtros,
.c-block-6__tags-filtros--list {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
}
.c-block-6__tags-filtros--list > *,
.c-block-6__tags-filtros > * {
  margin: 0 10px 10px 0;
}
.c-block-6__tags-filtros--list {
  max-width: 940px;
  margin: 0 auto 10px;
  transition: all 0.2s ease;
}
.jscroll-inner {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
  transition: all 0.2s ease;
}
.LIST {
  display: initial !important;
}
.LIST3 {
  max-width: 940px;
  margin: 0 auto;
  transition: all 0.2s ease;
}
.g-container-nd {
  min-height: calc(100vh - 299px);
  padding: 50px;
}
@media only screen and (max-width: 1400px) {
  .c-block-6 {
    background: none;
  }
}
@media only screen and (max-width: 600px) {
  .jscroll-inner {
    display: block;
    grid-template-columns: none;
    grid-column-gap: 0px;
    transition: all 0.2s ease;
  }
}
